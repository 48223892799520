import { createSelector } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { RootState } from "@/types/RootState.js";
import { initialState } from "./slice.js";
import { SecondaryAccountType } from "./types.js";

const selectDomain = (state: RootState) => state?.useUserState || initialState;

export const selectUserState = createSelector([selectDomain], (userStateState) => userStateState);

export const isLoggedInLinkedin = createSelector([selectDomain], (userStateState) => !!userStateState?.auth?.linkedinAccount?.profile?.linkedinId);

export const isLoggedInApi = createSelector(
    [selectDomain],
    (userStateState) => !!userStateState?.auth?.secondaryAccount?.apiToken || !!userStateState?.auth?.rootAccount?.apiToken,
);

export const isLoadingUser = createSelector([selectDomain], (userStateState) => userStateState.isLoadingUser);

export const getApiToken = createSelector(
    [selectDomain],
    (userStateState) => (userStateState?.auth?.secondaryAccount?.apiToken || userStateState?.auth?.rootAccount?.apiToken) ?? Cookies.get("token"),
);

export const getProfilePicture = createSelector(
    [selectDomain],
    (userStateState) =>
        userStateState?.auth?.secondaryAccount?.user?.linkedinAccount?.profilePicture ||
        userStateState?.auth?.rootAccount?.user?.linkedinAccount?.profilePicture ||
        userStateState?.auth?.linkedinAccount?.profile?.profilePicture,
);

export const isCurrentlyImpersonating = createSelector(
    [selectDomain],
    (userStateState) =>
        !!(userStateState?.auth?.secondaryAccount?.apiToken && userStateState?.auth?.secondaryAccount.type === SecondaryAccountType.IMPERSONATION),
);

export const getApiStaffToken = createSelector([selectDomain], (userStateState) => userStateState?.auth?.rootAccount?.apiToken);

export const getRootLinkedinProfile = createSelector([selectDomain], (userStateState) => userStateState?.auth?.linkedinAccount?.profile);

export const getLinkedinProfile = createSelector(
    [selectDomain],
    (userStateState) => userStateState?.auth?.secondaryAccount?.profile || userStateState?.auth?.linkedinAccount?.profile,
);

export const isReloadExtension = createSelector([selectDomain], (userStateState) => userStateState.reloadExtension);

export const getSecondaryApiToken = createSelector([selectDomain], (userStateState) => userStateState?.auth?.secondaryAccount?.apiToken);

export const getRootUserId = createSelector([selectDomain], (userStateState) => userStateState?.auth?.rootAccount?.user?._id?.toString());

export const getUserId = createSelector(
    [selectDomain],
    (userStateState) => userStateState?.auth?.secondaryAccount?.user?._id?.toString() || userStateState?.auth?.rootAccount?.user?._id?.toString(),
);

export const getUserLanguage = createSelector([selectDomain], (userStateState) => userStateState?.auth?.rootAccount?.user?.language);

export const hasCompletedStripeOnboarding = createSelector(
    [selectDomain],
    (userStateState) =>
        !!(
            userStateState?.auth?.secondaryAccount?.user?.stripeAccount?.state?.onBoarding?.endedAt ||
            userStateState?.auth?.rootAccount?.user?.stripeAccount?.state?.onBoarding?.endedAt
        ),
);

export const getUser = createSelector(
    [selectDomain],
    (userStateState) => userStateState?.auth?.secondaryAccount?.user || userStateState?.auth?.rootAccount?.user,
);

export const isLoggedInAnOtherAccountSelector = createSelector(
    [selectDomain],
    (userStateState) =>
        !!(userStateState?.auth?.secondaryAccount?.user && userStateState?.auth?.secondaryAccount?.type === SecondaryAccountType.STAFF_CRM_ACCESS),
);

export const isLoginToAnOtherAccountSelector = createSelector([selectDomain], (userStateState) => !!userStateState?.isLoginToAnOtherAccount);

export const getMyIdentity = createSelector([selectDomain], (userStateState) => {
    const user = userStateState?.auth?.secondaryAccount?.user || userStateState?.auth?.rootAccount?.user;
    if (user?.identity?.firstName && user?.identity?.lastName) {
        return `${user.identity.firstName} ${user.identity.lastName}`;
    }

    if (user?.identity?.firstName) {
        return `${user.identity.firstName}`;
    }

    if (user?.identity?.lastName) {
        return `${user.identity.lastName}`;
    }

    if (user?.linkedinAccount?.firstName && user?.linkedinAccount?.lastName) {
        return `${user.linkedinAccount.firstName} ${user.linkedinAccount.lastName}`;
    }

    if (user?.linkedinAccount?.firstName) {
        return `${user.linkedinAccount.firstName}`;
    }

    if (user?.linkedinAccount?.lastName) {
        return `${user.linkedinAccount.lastName}`;
    }

    if (user?.email) {
        return `${user?.email}`;
    }
    return "";
});

export const getWaalaxyId = createSelector(
    [selectDomain],
    (userStateState) =>
        userStateState?.auth?.secondaryAccount?.user?.waalaxyId?.toString() || userStateState?.auth?.rootAccount?.user?.waalaxyId?.toString(),
);

export const getHasRequirements = createSelector([selectDomain], (userStateState) =>
    Boolean(
        userStateState?.auth.secondaryAccount
            ? userStateState?.auth.secondaryAccount?.user?.stripeAccount?.state?.mustBeUpdated
            : userStateState?.auth.rootAccount?.user?.stripeAccount?.state?.mustBeUpdated,
    ),
);

export const getIntercomIdentifiers = createSelector([selectDomain], (userStateState) => userStateState?.intercomIdentifiers);

export const isStripeAccount = createSelector([selectDomain], (userStateState) =>
    Boolean(userStateState?.auth?.secondaryAccount?.user?.stripeAccount?.id || userStateState?.auth?.rootAccount?.user?.stripeAccount?.id),
);

export const getHasAgreedGoldenRules = createSelector([selectDomain], (userStateState) =>
    Boolean(
        userStateState?.auth?.secondaryAccount?.user?.metadata?.hasAgreedGoldenRulesAt ||
            userStateState?.auth?.rootAccount?.user?.metadata?.hasAgreedGoldenRulesAt,
    ),
);
