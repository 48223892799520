import { Text, flex } from "@waapi/waap-ui";
import styled from "styled-components";

export const InlineText = styled(Text)`
    display: inline-block;
`;

export const ImportProgressionWrapper = styled.div`
    ${flex};
    padding: ${({ theme }) => theme.spacing[12]} 0 ${({ theme }) => theme.spacing[4]};
    padding: ${({ theme }) => theme.spacing[12]} 0 ${({ theme }) => theme.spacing[4]};
    max-width: 100%;
    min-width: 0;
`;
