import { getCurrentStagingEnvName } from "../helpers/getCurrentStagingEnvName.js";
import { PALPATINE_BACK_HOSTS } from "@waapi/environments-repository";

export const IN_PRODUCTION = window.location.host.includes("waalaxy.com");

export const CHROME_EXTENSION_ID =
    IN_PRODUCTION === false ? "feiclbomfllbkdmeibcljmfbdakimmdg" /* GAMMA EXTENSION ID */ : "hlkiignknimkfafapmgpbnbnmkajgljh";

export const CRHOME_EXTENSION_URL = "https://chrome.google.com/webstore/detail/waalaxy-ex-prospectin-pro/hlkiignknimkfafapmgpbnbnmkajgljh";

export const MINIMAL_VERSION = "1.0.7";

export const WAALAXY_URL = `${process.env.VITE_PALPATINE_WAALAXY_URL || "https://app.waalaxy.com"}`;

export const LOCAL_STORAGE_AMBASSADOR_ID_KEY = "ambassadorId";

export const DEFAULT_PAGE = 1;
export const DEFAULT_ITEMS_PER_PAGE = 100;
export const DEFAULT_ITEMS_PER_PAGE_OPTIONS = [10, 25, 50, 100];
export const DEFAULT_CAMPAIGN_STATUS = "running" as const;

export const CAMPAIGN_FETCHING_SIZE = 25 as const;
export const CAMPAIGN_BULKBAR_FETCHING_SIZE = 10 as const;
export const TAGS_FETCHING_SIZE = 10 as const;
export const CAMPAIGN_ACTION_FILTER_FETCHING_SIZE = 10 as const;
export const TRAVELER_FETCHING_SIZE = 20 as const;

export const MAX_NUMBER_OF_SEAT_COMPANY = 110;

export const ACTIONS_TYPE_GOAL = {
    LINKEDIN: "linkedin",
    EMAIL: "mail",
} as const;

export const CAMPAIGN_STATES = ["running", "paused", "draft", "stopped"] as const;

export const ADD_PROSPECTS_INPUT_TABS = {
    prospects: {
        label: "Prospects",
        value: "prospects",
    },
    triggers: {
        label: "Auto Import",
        value: "triggers",
    },
};

export const RESPONSE_CODES = {
    COMPANY_INVITATION: {
        USER_ALREADY_IN_COMPANY: "R025409-001",
    },
};

export const AMBASSADOR_BANNED_HTTP_RESPONSE_CODE = 999;

const currentEnv = getCurrentStagingEnvName();

export const API_URL = (() => {
    if (window.location.host.includes("localhost") || window.location.host.includes("127.0.0.1")) {
        return new URL("api", process.env.VITE_API_URL).toString();
    }
    return new URL("api", PALPATINE_BACK_HOSTS[currentEnv]).toString();
})();
