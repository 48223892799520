import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { Text, TextSize } from "@waapi/waap-ui";
import { Trans } from "react-i18next";
import { useTheme } from "styled-components";
import { ImportDoneWrapper } from "./styles.js";
import { ImportDoneProps } from "./types.js";
import { WAALAXY_URL } from "@/utils/constants.js";

export const ImportDone = ({ progression, maxProspectCount, listData }: ImportDoneProps) => {
    const { translations } = useTranslation();
    const theme = useTheme();

    const scanned = progression?.scanned || 0;
    const success = progression?.success || 0;
    const error = progression?.error || 0;
    const duplicates = progression?.duplicates || 0;
    const prospectListName = listData?.prospectList?.name || "";

    return (
        <ImportDoneWrapper direction="column" gap="12">
            <Text size={TextSize.SMALL}>
                <Trans
                    i18nKey={translations.import.import_finished_text()}
                    values={{ prospectCount: success, prospectDuplicatedCount: duplicates, prospectList: prospectListName }}
                    components={{
                        lnk: (
                            // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
                            <a
                                target="_blank"
                                href={`${WAALAXY_URL}${!listData?.prospectList?._id ? "/prospects" : `/prospects/${listData.prospectList._id}`}`}
                                rel="noreferrer"
                            />
                        ),
                    }}
                />
            </Text>
            <Text size={TextSize["X-SMALL"]} color={theme.grayscale[80]}>
                <Trans
                    i18nKey={translations.import.progress_summary()}
                    components={{
                        strong: <strong />,
                    }}
                    values={{
                        max: maxProspectCount,
                        scanned,
                        success,
                        error,
                        duplicates,
                    }}
                />
            </Text>
        </ImportDoneWrapper>
    );
};
