import { CustomTFunction, TranslationDictionary } from "@/customHooks/useTranslation/types.js";
import { ContactAdvantagesEnum } from "@waapi/palpatine-client";

export const getAdvantagesTranslations = ({
    t,
    translations,
}: {
    t: CustomTFunction;
    translations: TranslationDictionary;
}): Record<ContactAdvantagesEnum, string> => ({
    [ContactAdvantagesEnum.ADVANTAGE_30]: t(translations.pages.choose_advantage.advantages.advantage_30()),
    [ContactAdvantagesEnum.ADVANTAGE_30_20]: t(translations.pages.choose_advantage.advantages.advantage_30_20()),
    [ContactAdvantagesEnum.ADVANTAGE_50]: t(translations.pages.choose_advantage.advantages.advantage_50()),
});
