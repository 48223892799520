import { AMBASSADOR_BANNED_HTTP_RESPONSE_CODE } from "@/utils/constants.js";
import { Axios } from "axios";
import { NavigateFunction } from "react-router-dom";

export const setupAxiosBanInterceptors = (axiosInstance: Axios, navigate: NavigateFunction) => {
    axiosInstance.interceptors.response.use(null, (error) => {
        const responseCode = error.response.status;
        const isBannedResponseCode = responseCode === AMBASSADOR_BANNED_HTTP_RESPONSE_CODE;
        if (isBannedResponseCode) {
            navigate("/logout");
        }
        return Promise.reject(error);
    });
};
