import { Sidebar } from "@waapi/waap-ui";
import styled from "styled-components";

export const StyledSidebar = styled(Sidebar)`
    min-width: 5rem;
`;

export const StyledSelectLanguageDiv = styled.div`
    margin-top: ${(props) => props.theme.spacing[16]};
    min-width: 2.5rem;
`;

export const PremiumAdContainer = styled.div<{ isSmall: boolean }>`
    display: flex;
    justify-content: ${(props) => (props.isSmall ? "center" : "flex-start")};
    align-items: center;
`;

export const Title = styled.img`
    height: 3rem;
`;
