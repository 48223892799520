import { useExtensionManager } from "@/customHooks/useExtensionManager/index.js";
import useToasts from "@/customHooks/useToasts.js";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { getWaalaxyExtensionState } from "@/customHooks/useWaalaxyExtension/selectors.js";
import { actions, reducer, sliceKey } from "@/customHooks/useWaalaxyExtension/slice.js";
import { UseWaalaxyImportReturnType } from "@/customHooks/useWaalaxyExtension/types.js";
import { useAffiliatesManager } from "@/entities/Afffiliate/hooks/useAffiliatesManager/index.js";
import { loggedToLinkedin } from "@/services/ChromeMessaging/Global/LoggedToLinkedin.js";
import { versionCheck } from "@/services/ChromeMessaging/Global/versionCheck.js";
import { CHROME_EXTENSION_ID } from "@/utils/constants.js";
import { isNavigatorIsChromium, isNavigatorValidAndWithAnExtension } from "@/utils/validateNavigator.js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "redux-injectors";

export const useWaalaxyExtension = (checkExtension = false): UseWaalaxyImportReturnType => {
    useInjectReducer({ key: sliceKey, reducer });
    const INTERVAL_CHECK_MS = 30000;
    const [intervalId, setIntervalId] = useState<NodeJS.Timer | undefined>(undefined);

    const { t, translations } = useTranslation();
    const dispatch = useDispatch();
    const waalaxyExtensionState = useSelector(getWaalaxyExtensionState);
    const { importAffiliates } = useExtensionManager();
    const { addErrorToast } = useToasts();
    const { getAffiliatesLinkedinIds } = useAffiliatesManager();

    const moveAffiliatesToWaalaxy: UseWaalaxyImportReturnType["moveAffiliatesToWaalaxy"] = async (params) => {
        const { selectedAffiliates, filters } = params;
        if (!waalaxyExtensionState?.extensionState?.userId) {
            addErrorToast(t(translations.pages.affiliates.bulkbar.move_affiliates.error.missing_user()));
            return false;
        }

        const linkedinIds = await getAffiliatesLinkedinIds({ affiliateSelection: selectedAffiliates, affiliateFilters: filters });
        if (!Array.isArray(linkedinIds)) {
            return false;
        }

        await importAffiliates({
            ...params,
            allowDuplicate: true,
            linkedinIds,
            user: waalaxyExtensionState.extensionState.userId,
        });

        return true;
    };

    const clearExtensionStateInterval = () => {
        if (intervalId) {
            clearInterval(intervalId);
        }
    };

    const checkIfExtensionInstalled = async () => {
        if (!isNavigatorIsChromium()) return;
        const extVersion = await versionCheck();
        if (!extVersion) return;
        const isLoggedToLinkedin = await loggedToLinkedin();
        if (!isLoggedToLinkedin) return;
        dispatch(actions.setExtensionIsInstalled(true));
        clearExtensionStateInterval();
    };

    const checkWaalaxyExtensionState = async () => {
        if (isNavigatorValidAndWithAnExtension()) {
            const port = window.chrome.runtime.connect(CHROME_EXTENSION_ID, {
                name: "store",
            });

            port.onMessage.addListener((msg) => {
                const waalaxyIdFromExtension: string = msg?.auth?.secondary?.userId ?? msg?.auth?.rootAccount?.userId;
                const email: string = msg?.auth?.linkedinAccount?.profile?.email;
                const profilePicture: string = msg?.auth?.linkedinAccount?.profile?.profilePicture;
                const firstName: string = msg?.auth?.linkedinAccount?.profile?.firstName;
                const lastName: string = msg?.auth?.linkedinAccount?.profile?.lastName;
                const companyName: string = msg?.auth?.linkedinAccount?.profile?.company?.name;
                const publicIdentifier: string = msg?.auth?.linkedinAccount?.profile?.publicIdentifier;
                const memberId: string = msg?.auth?.linkedinAccount?.profile?.memberId;
                dispatch(
                    actions.setWaalaxyExtensionState({
                        userId: waalaxyIdFromExtension,
                        email,
                        profilePicture,
                        firstName,
                        lastName,
                        companyName,
                        publicIdentifier,
                        memberId,
                    }),
                );
            });
        }
    };

    const checkExtensionState: UseWaalaxyImportReturnType["checkExtensionState"] = async () => {
        dispatch(actions.reset());
        checkIfExtensionInstalled();
        checkWaalaxyExtensionState();
    };

    useEffect(() => {
        if (checkExtension) {
            checkExtensionState();
            setIntervalId(setInterval(checkExtensionState, INTERVAL_CHECK_MS));
        }

        return clearExtensionStateInterval;
    }, [checkExtension]);

    return {
        moveAffiliatesToWaalaxy,
        checkExtensionState,
        ...waalaxyExtensionState,
    };
};
