import { UseAffiliationErrorsReturnType } from "@/entities/AffiliationError/hooks/useAffiliationErrors/types.js";
import { useAffiliationErrorManager } from "@/entities/AffiliationError/hooks/useAffiliationErrorManager/index.js";
import { useInjectReducer } from "redux-injectors";
import { actions, reducer, sliceKey } from "@/entities/AffiliationError/hooks/useAffiliationErrors/slice.js";
import { useDispatch, useSelector } from "react-redux";
import { selectUseAffiliationErrors } from "@/entities/AffiliationError/hooks/useAffiliationErrors/selectors.js";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export const useAffiliationErrors = (): UseAffiliationErrorsReturnType => {
    useInjectReducer({ key: sliceKey, reducer });

    const [key] = useState<string>(uuidv4());
    const state = useSelector(selectUseAffiliationErrors(key));

    const dispatch = useDispatch();
    const { setAffiliationErrorsProcessed, getAffiliationErrors } = useAffiliationErrorManager();

    const fetchAffiliationErrors = async () => {
        dispatch(actions.setLoading({ key, loading: true }));
        const affiliationErrors = await getAffiliationErrors();
        dispatch(actions.setAffiliationErrors({ key, affiliationErrors }));
        dispatch(actions.setLoading({ key, loading: false }));
    };

    const setAffiliationErrorProcessed: UseAffiliationErrorsReturnType["setAffiliationErrorProcessed"] = async (affiliationErrorsIds) => {
        dispatch(actions.setLoading({ key, loading: true }));
        const isProcessed = await setAffiliationErrorsProcessed({ affiliationErrors: affiliationErrorsIds });
        if (isProcessed) {
            dispatch(actions.removeProcessedAffiliationErrors({ affiliationErrorsIds }));
        }
        dispatch(actions.setLoading({ key, loading: false }));
    };

    useEffect(() => {
        dispatch(actions.init(key));
        fetchAffiliationErrors();

        return () => {
            dispatch(actions.removeState(key));
        };
    }, []);

    return {
        ...state,
        setAffiliationErrorProcessed,
    };
};
