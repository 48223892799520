import { useEffect, useState } from "react";
import useRequest from "./useRequest.js";
import { OTHER_COUNTRIES_CODE } from "@waapi/palpatine-client";
import { useTranslation } from "./useTranslation/index.js";

export const useCountryList = () => {
    const { t, translations } = useTranslation();
    const [loadingCountry, setLoadingCountry] = useState<boolean>(true);
    const [countryList, setCountryList] = useState<Array<{ code: string; name: string }>>([]);
    const getCountryListRequest = useRequest<"getCountryList">("getCountryList");

    useEffect(() => {
        (async function () {
            try {
                const countryListResponse = await getCountryListRequest();
                if (!countryListResponse.hasFailed) {
                    setCountryList([
                        ...countryListResponse.data.countryList,
                        { code: OTHER_COUNTRIES_CODE, name: t(translations.other_countries()) },
                    ]);
                }
            } finally {
                setLoadingCountry(false);
            }
        })();
    }, []);

    return {
        countryList,
        loadingCountry,
    };
};
