import useRequest from "@/customHooks/useRequest.js";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUser } from "../useUserState/selectors.js";
import { actions } from "../useUserState/slice.js";
import { getGoldenRules } from "./helpers.js";
import { UseGoldenRulesReturnType } from "./types.js";

export const useGoldenRules = (): UseGoldenRulesReturnType => {
    const { t, translations } = useTranslation();
    const user = useSelector(getUser);
    const [checkedRules, setCheckedRules] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const acceptContract = useRequest<"acceptContract">("acceptContract");

    const goldenRules = useMemo(() => getGoldenRules({ language: user?.language, t, translations }), [user?.language]);

    const toggleRules = () => {
        setCheckedRules(!checkedRules);
    };

    const handleAcceptRules = () => {
        acceptContract();
        dispatch(actions.setAcceptGoldenRules());
    };

    const handleRefuseRules = () => {
        navigate("/logout");
    };

    return {
        goldenRules,
        checkedRules,
        toggleRules,
        handleRefuseRules,
        handleAcceptRules,
    };
};
