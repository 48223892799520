import { getProfilePicture } from "@/entities/User/hooks/useUserState/selectors.js";
import { useSelector } from "react-redux";
import baseAvatar from "../../../assets/blue-flat_waalien.svg";
import { Avatar } from "./style.js";
import { MyAvatarProps } from "./types.js";

export const MyAvatar = (props: MyAvatarProps): JSX.Element => {
    const profilePicture = useSelector(getProfilePicture);

    return (
        <Avatar
            {...props}
            src={profilePicture ?? baseAvatar}
            onError={(e: any) => {
                e.target.src = baseAvatar;
            }}
        />
    );
};
