import { CustomTFunction, TranslationDictionary } from "@/customHooks/useTranslation/types.js";
import * as Yup from "yup";

export enum EmailInputErrors {
    EMAIL_REQUIRED = "emailRequired",
    EMAIL_NOT_EMAIL = "emailNotEmail",
    EMAIL_ALREADY_TAKEN = "emailAlreadyTaken",
}

export const emailInputErrorMap = ({
    t,
    translations,
}: {
    t: CustomTFunction;
    translations: TranslationDictionary;
}): Record<EmailInputErrors, string> => ({
    [EmailInputErrors.EMAIL_REQUIRED]: t(translations.forms.auth.email.feedback.error.required()),
    [EmailInputErrors.EMAIL_NOT_EMAIL]: t(translations.forms.auth.email.feedback.error.not_email()),
    [EmailInputErrors.EMAIL_ALREADY_TAKEN]: t(translations.forms.auth.email.feedback.error.already_taken()),
});

export const emailInputValidationSchema = Yup.object({
    email: Yup.string().required(EmailInputErrors.EMAIL_REQUIRED).email(EmailInputErrors.EMAIL_NOT_EMAIL),
});
