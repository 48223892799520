import { ImportView } from "../constants.js";
import { isValidCSVImportMessage } from "../helpers/isValidCSVImportMessage.js";
import { UseImportStateState } from "../types.js";
import { initialKeyState as initialImportState } from "../slice.js";

export const handleCSVImportMessage = ({
    message,
    view,
    isHidden,
}: {
    message: unknown;
    view?: ImportView;
    isHidden: boolean;
}):
    | {
          importId: string;
          stateUpdate?: Partial<UseImportStateState[string]>;
      }
    | undefined => {
    if (!isValidCSVImportMessage(message)) return undefined;

    if (message.type === "CURRENT_STATUS" && message.payload.onGoingExport) {
        const {
            metadata: { toList, quantity, id, toUser },
            progression,
        } = message.payload;

        return {
            importId: id,
            stateUpdate: {
                view: ImportView.IMPORT_PROGRESSION,
                listData: {
                    prospectList: toList,
                    loading: false,
                },
                maxProspectCount: quantity,
                toastId: id,
                importProgression: progression,
                importId: id,
                user: toUser,
            },
        };
    }
    if (message.type === "IMPORT_CSV_PROGRESSION" && (view === ImportView.IMPORT_PROGRESSION || view === ImportView.IMPORT_STARTED)) {
        const {
            progression,
            metadata: { quantity, id },
        } = message.payload;

        return {
            importId: id,
            stateUpdate: {
                ...(view !== ImportView.IMPORT_PROGRESSION ? { view: ImportView.IMPORT_PROGRESSION } : {}),
                maxProspectCount: quantity,
                importProgression: progression,
            },
        };
    }
    if (message.type === "IMPORT_CSV_STARTED") {
        const { toUser, toList, quantity, id } = message.payload;

        return {
            importId: toList._id,
            stateUpdate: {
                view: ImportView.IMPORT_STARTED,
                maxProspectCount: quantity,
                listData: {
                    prospectList: toList,
                    loading: false,
                },
                toastId: id,
                importId: id,
                importProgression: {
                    scanned: 0,
                    duplicates: 0,
                    error: 0,
                    success: 0,
                },
                user: toUser,
            },
        };
    }
    if (message.type === "IMPORT_CSV_STOPPED") {
        const { id } = message.payload;

        if (isHidden) {
            return {
                importId: id,
                stateUpdate: initialImportState,
            };
        }

        return {
            importId: id,
            stateUpdate: {
                view: ImportView.IMPORT_STOPPED,
            },
        };
    }
    if (message.type === "EXPORT_LIMIT_REACHED") {
        const {
            metadata: { toList, id, quantity },
            progression: { success },
            limitReached: limitType,
        } = message.payload;

        return {
            importId: id,
            stateUpdate: {
                importLimits: {
                    limitType,
                    errorsCount: quantity - success,
                    name: toList.name,
                },
            },
        };
    }

    return undefined;
};
