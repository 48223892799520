import spaceSvg from "@/assets/space.svg";
import { Text } from "@waapi/waap-ui";
import styled from "styled-components";

export const StyledContainer = styled.div`
    background: url(${spaceSvg}) no-repeat center fixed, ${(props) => props.theme.gradients.darkSpace};
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1.5rem;
`;

export const StyledText = styled(Text)`
    > strong {
        color: ${({ theme }) => theme.colors.tertiary[100]};
        cursor: pointer;
    }
`;
