import sendMessage from "@/utils/chromeMessages.js";
import { ProspectLists } from "@/customHooks/useExtensionManager/types.js";

export function getProspectsLists(): Promise<ProspectLists | null> {
    return sendMessage<ProspectLists>({
        action: "PROSPECT_LIST/GET_ALL",
    })
        .then((prospectsLists) => {
            return prospectsLists;
        })
        .catch(() => {
            return null;
        });
}
