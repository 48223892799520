import { Routes } from "react-router-dom";
import { RoutesTypes } from "../../../types/routes.types.js";
import { buildRoutes } from "../../../utils/buildRoutes.utils.js";
import { ForgotPassword } from "./ForgotPassword/index.js";

const forgotPasswordRoutes: RoutesTypes = [
    {
        path: "/",
        element: <ForgotPassword />,
    },
];

export const ForgotPasswordRoutes = (): JSX.Element => {
    return <Routes>{buildRoutes(forgotPasswordRoutes)}</Routes>;
};
