import styled from "styled-components";
import { Text } from "@waapi/waap-ui";

export const FieldContent = styled.div<{ fullWidth?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[2]};
    width: ${(props) => (props.fullWidth ? "100%" : "auto")};
`;

export const FieldText = styled(Text)`
    display: flex;
    flex-direction: row;
    gap: ${(props) => props.theme.spacing[12]};
`;
