import { forwardRef } from "react";
import { StyledIconButton, StyledLink } from "./styles.js";

export const SmallCustomizableButton = forwardRef<HTMLDivElement>((_, ref): JSX.Element => {
    return (
        <StyledLink ref={ref}>
            <StyledIconButton icon="filledSendMessage" iconColor="white" size="small" />
        </StyledLink>
    );
});
