import { Routes } from "react-router-dom";
import { RoutesTypes } from "../../../types/routes.types.js";
import { buildRoutes } from "../../../utils/buildRoutes.utils.js";
import { FAQ } from "./FAQ/Loadable.js";

const faqRoutes: RoutesTypes = [
    {
        path: "/",
        element: <FAQ />,
    },
];

export const FaqRoutes = (): JSX.Element => {
    return <Routes>{buildRoutes(faqRoutes)}</Routes>;
};
