import { CustomTFunction, TranslationDictionary } from "@/customHooks/useTranslation/types.js";
import * as Yup from "yup";

export enum CountrySelectErrors {
    COUNTRY_REQUIRED = "countryRequired",
}

export const countrySelectErrorMap = ({
    t,
    translations,
}: {
    t: CustomTFunction;
    translations: TranslationDictionary;
}): Record<CountrySelectErrors, string> => ({
    [CountrySelectErrors.COUNTRY_REQUIRED]: t(translations.forms.auth.country.feedback.error.required()),
});

export const countrySelectValidationSchema = Yup.object({
    country: Yup.string().required(CountrySelectErrors.COUNTRY_REQUIRED),
});
