import { Avatar, Text, TextSize, TextWeight } from "@waapi/waap-ui";
import baseAvatar from "@/assets/blue-flat_waalien.svg";
import React from "react";
import { StyledRedCard } from "./styles.js";
import { AffiliationErrorProps } from "./types.js";

export const AffiliationError = (props: AffiliationErrorProps): JSX.Element => {
    const { affiliationError } = props;

    return (
        <StyledRedCard gap="12" direction="row" justify="center" align="center">
            <Avatar
                src={affiliationError?.affiliate?.profilePicture ?? baseAvatar}
                onError={(e: any) => {
                    e.target.src = baseAvatar;
                }}
            />
            <Text size={TextSize.MEDIUM} weight={TextWeight.NORMAL}>
                {affiliationError.affiliate.name}
            </Text>
        </StyledRedCard>
    );
};
