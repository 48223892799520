import { AffiliationErrorTypeEnum } from "@waapi/palpatine-client";
import { CustomTFunction, TranslationDictionary } from "@/customHooks/useTranslation/types.js";
import { errorTypeTexts } from "@/entities/AffiliationError/constants/errorTypeTexts.js";

export const getErrorTypeText = (params: {
    errorType: AffiliationErrorTypeEnum;
    t: CustomTFunction;
    translations: TranslationDictionary;
}): string => {
    const { errorType, t, translations } = params;

    return errorTypeTexts({ t, translations })[errorType];
};
