import { BaseInput } from "@/app/components/Inputs/BaseInput/index.js";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { ITheme } from "@waapi/waap-ui";
import { Field } from "formik";
import { useTheme } from "styled-components";
import { EmailInputProps } from "./types.js";
import { emailInputErrorMap } from "./validation.js";

export const EmailInput = ({ formState }: EmailInputProps): JSX.Element => {
    const theme = useTheme() as ITheme;
    const { t, translations } = useTranslation();

    const emailInputErrors = emailInputErrorMap({ t, translations });

    return (
        <Field name="email">
            {({ field, meta }) => (
                <BaseInput
                    {...field}
                    onChange={(e) => {
                        const value = e.target.value || "";
                        formState.setFieldValue("email", value.toLowerCase());
                    }}
                    labelInfos={{ text: t(translations.forms.auth.email.label()), color: theme.grayscale.white }}
                    feedback={meta.touched && meta.error && { type: "error", children: emailInputErrors[meta.error] }}
                    type="text"
                    value={formState.values.email}
                    disabled={formState.isSubmitting}
                    placeholder={t(translations.forms.auth.email.placeholder())}
                    status={meta.error ? "error" : undefined}
                    autoCapitalize="none"
                />
            )}
        </Field>
    );
};
