import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { Flex, ITheme, Icon, Spinner, Text, TextSize } from "@waapi/waap-ui";
import { Field } from "formik";
import { useTheme } from "styled-components";
import { useCountryList } from "../../../../../customHooks/useCountryList.js";
import { BaseSelect } from "../../../Inputs/BaseSelect/index.js";
import { CountrySelectProps } from "./types.js";
import { countrySelectErrorMap } from "./validation.js";

export const CountrySelect = ({ formState }: CountrySelectProps): JSX.Element => {
    const theme = useTheme() as ITheme;
    const { t, translations } = useTranslation();

    const countrySelectErrors = countrySelectErrorMap({ t, translations });
    const { countryList, loadingCountry } = useCountryList();

    return (
        <Field name="country" as="select">
            {({ form, field, meta }) => (
                <Flex direction="column" gap="4">
                    <BaseSelect
                        {...field}
                        labelInfos={{
                            text: (
                                <>
                                    {t(translations.forms.auth.country.label())} {loadingCountry && <Spinner />}
                                </>
                            ),
                            color: theme.grayscale.white,
                        }}
                        feedback={
                            meta.touched && meta.error
                                ? { type: "error", children: countrySelectErrors[meta.error], textColor: theme.grayscale.white }
                                : undefined
                        }
                        isSearchable
                        value={formState.values.country ? countryList.find((country) => country.code === formState.values.country)?.code : ""}
                        onChange={(option) => form.setFieldValue(field.name, option)}
                        disabled={loadingCountry || formState.isSubmitting}
                        placeholder={t(translations.forms.auth.country.placeholder())}
                        status={meta.error ? "error" : undefined}
                        options={countryList.map((country) => ({
                            value: country.code,
                            label: country.name,
                        }))}
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: (base) => ({ ...base, zIndex: theme.elevationLevels.nine }),
                        }}
                    />
                    {!meta.touched && !meta.error && (
                        <Flex gap="4" align="center">
                            <Icon size="small" icon="information" iconColor={theme.colors.primary[100]} />
                            <Text size={TextSize["XX-SMALL"]} color={theme.grayscale.white}>
                                {t(translations.forms.auth.country.feedback.help.requirements())}
                            </Text>
                        </Flex>
                    )}
                </Flex>
            )}
        </Field>
    );
};
