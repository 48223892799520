import { Avatar as InnerAvatar } from "@waapi/waap-ui";
import styled from "styled-components";
import { MyAvatarProps } from "./types.js";

export const Avatar = styled(InnerAvatar)<MyAvatarProps>`
    ${(props) =>
        "customSize" in props &&
        `
        height: ${props.customSize.height}px;
        width: ${props.customSize.width}px;
    `}
`;
