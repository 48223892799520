/* eslint-disable react/jsx-no-useless-fragment */
import { ConfirmLinkWaalaxy } from "@/app/components/ConfirmLinkWaalaxy/index.js";
import { useWaalaxyExtension } from "@/customHooks/useWaalaxyExtension/index.js";
import { useModal } from "@waapi/waap-ui";
import { useSelector } from "react-redux";
import { getWaalaxyId } from "../../../entities/User/hooks/useUserState/selectors.js";
import { LargeAskWaalaxyAccount } from "./LargeAskWaalaxyAccount/index.js";
import { SmallAskWaalaxyAccount } from "./SmallAskWaalaxyAccount/index.js";
import { AskWaalaxyAccountProps } from "./types.js";

export function AskWaalaxyAccount({ size }: AskWaalaxyAccountProps): JSX.Element {
    const { extensionIsInstalled } = useWaalaxyExtension();
    const waalaxyId = useSelector(getWaalaxyId);
    const { extensionState: waalaxyExtensionState } = useWaalaxyExtension();
    const [isOpen, toggle] = useModal({
        name: "confirm-link-account",
        defaultIsOpen: false,
        useUrl: false,
    });

    if (!extensionIsInstalled || !waalaxyExtensionState?.userId || waalaxyId) return <></>;

    return (
        <>
            <ConfirmLinkWaalaxy isOpen={isOpen} onModalClose={toggle} />
            {size === "small" ? <SmallAskWaalaxyAccount onClick={toggle} /> : <LargeAskWaalaxyAccount onClick={toggle} />}
        </>
    );
}
