import { Routes } from "react-router-dom";
import { RoutesTypes } from "../../../types/routes.types.js";
import { buildRoutes } from "../../../utils/buildRoutes.utils.js";
import { Logout } from "./Logout/index.js";

const logoutRoutes: RoutesTypes = [
    {
        path: "/",
        element: <Logout />,
    },
];

export const LogoutRoutes = (): JSX.Element => {
    return <Routes>{buildRoutes(logoutRoutes)}</Routes>;
};
