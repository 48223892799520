import { Button, Heading, HeadingSize, HeadingWeight } from "@waapi/waap-ui";
import { ReactComponent as RocketCrashedSvg } from "../../../assets/rocket-crashed.svg";
import { useTranslation } from "../../../customHooks/useTranslation/index.js";
import { FloatingLanguageSelect } from "../FloatingLanguageSelect/index.js";
import { StyledContainer, StyledText } from "./style.js";

export function AppCrashed(): JSX.Element {
    const { t, translations } = useTranslation();

    return (
        <StyledContainer>
            <FloatingLanguageSelect size="large" variant="light" />

            <RocketCrashedSvg />
            <Heading color="white" size={HeadingSize.SMALL} weight={HeadingWeight.BOLD}>
                {t(translations.app_crashed.title())}
            </Heading>
            <StyledText color="white">
                {t(translations.app_crashed.text_1())}
                <br />
                {t(translations.app_crashed.text_2())}
            </StyledText>

            <Button onClick={() => window.location.reload()}>{t(translations.app_crashed.refresh())}</Button>
        </StyledContainer>
    );
}
