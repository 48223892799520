import { ConfirmLinkWaalaxyProps } from "@/app/components/ConfirmLinkWaalaxy/types.js";
import { HorizontalSeparator } from "@/app/components/HorizontalSeparator/index.js";
import AlienWithBackground from "@/assets/alien-background.png";
import baseAvatar from "@/assets/blue-flat_waalien.svg";
import LeftPowerCable from "@/assets/left-power-cable.png";
import RightPowerCable from "@/assets/right-power-cable.png";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { useWaalaxyExtension } from "@/customHooks/useWaalaxyExtension/index.js";
import { useAccountManager } from "@/entities/Account/hooks/useAccountManager/index.js";
import { Avatar, Button, Flex, Skeleton, SkeletonColor, SkeletonRadius, Text, TextSize, TextWeight } from "@waapi/waap-ui";
import { SyntheticEvent, useState } from "react";
import { useTheme } from "styled-components";
import { StyledImageCenter, StyledImageLeft, StyledImageRight, StyledModal, StyledProfileCard, StyledTextCenter } from "./styles.js";

export const ConfirmLinkWaalaxy = ({ isOpen, onModalClose }: ConfirmLinkWaalaxyProps): JSX.Element => {
    const { t, translations } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { linkAccount } = useAccountManager();

    const theme = useTheme();
    const { extensionState: waalaxyExtensionState } = useWaalaxyExtension();

    const onLink = async () => {
        if (!waalaxyExtensionState?.userId) return;
        const { userId, companyName, firstName, lastName, memberId, profilePicture, publicIdentifier } = waalaxyExtensionState;
        setIsSubmitting(true);
        await linkAccount({
            waalaxyId: userId,
            companyName,
            profilePicture,
            firstName,
            lastName,
            publicIdentifier,
            memberId,
        });
        setIsSubmitting(false);
        onModalClose();
    };

    return (
        <StyledModal isOpen={isOpen} hideCloseButton onClose={onModalClose} fluid={false}>
            <Flex gap="28" direction="column">
                <Flex justify="center" align="center">
                    <StyledImageLeft
                        src={LeftPowerCable}
                        dimensions={{ height: "5rem" }}
                        loader={<Skeleton radius={SkeletonRadius.SMALL} color={SkeletonColor.GREY} height="5rem" />}
                    />
                    <StyledImageCenter
                        src={AlienWithBackground}
                        dimensions={{ height: "5rem" }}
                        loader={<Skeleton radius={SkeletonRadius.SMALL} color={SkeletonColor.GREY} height="5rem" />}
                    />
                    <StyledImageRight
                        src={RightPowerCable}
                        dimensions={{ height: "5rem" }}
                        loader={<Skeleton radius={SkeletonRadius.SMALL} color={SkeletonColor.GREY} height="5rem" />}
                    />
                </Flex>
                <StyledTextCenter size={TextSize.LARGE} weight={TextWeight.SEMIBOLD}>
                    {t(translations.link_waalaxy.title())}
                </StyledTextCenter>
                <Flex align="start" justify="start">
                    <StyledProfileCard gap="12" direction="row" align="center">
                        <Avatar
                            src={waalaxyExtensionState?.profilePicture ?? baseAvatar}
                            onError={(e: SyntheticEvent<HTMLImageElement> & { target: HTMLImageElement }) => {
                                e.target.src = baseAvatar;
                            }}
                        />
                        <Text size={TextSize.SMALL} weight={TextWeight.SEMIBOLD}>
                            {waalaxyExtensionState?.email}
                        </Text>
                    </StyledProfileCard>
                </Flex>
                <Text size={TextSize.SMALL} color={theme.grayscale[80]}>
                    {t(translations.link_waalaxy.text())}
                </Text>
                <HorizontalSeparator />
                <Flex align="end" justify="end">
                    <Button onClick={onLink} loading={isSubmitting}>
                        {t(translations.link_waalaxy.cta_confirm())}
                    </Button>
                </Flex>
            </Flex>
        </StyledModal>
    );
};
