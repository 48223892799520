import { InputFeedback, Select, TextSize, TextWeight } from "@waapi/waap-ui";
import React from "react";
import { FieldContent, FieldText } from "./style.js";
import { BaseSelectProps } from "./types.js";

export const BaseSelect = ({ labelInfos, feedback, fullWidth, ...attributes }: BaseSelectProps): JSX.Element => {
    return (
        <FieldContent fullWidth={fullWidth}>
            {labelInfos && (
                <FieldText color={labelInfos.color} size={TextSize["X-SMALL"]} weight={TextWeight.SEMIBOLD}>
                    {labelInfos.text}
                </FieldText>
            )}

            <Select {...attributes} />

            {feedback && <InputFeedback {...feedback} />}
        </FieldContent>
    );
};
