/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable camelcase  */
// eslint-disable-next-line
/* eslint-disable @typescript-eslint/camelcase */
import { defaultTheme, ITheme } from "@waapi/waap-ui";

declare module "styled-components" {
    export interface DefaultTheme extends ITheme {}
}

export const lightTheme: ITheme = defaultTheme;
