import { Text, flex } from "@waapi/waap-ui";
import styled from "styled-components";

export const InlineText = styled(Text)`
    display: inline-block;
`;

export const ImportDoneWrapper = styled.div`
    ${flex};
    padding: ${({ theme: { spacing } }) => `${spacing[12]} 0 ${spacing[4]}`};
    max-width: 100%;
    min-width: 0;
`;
