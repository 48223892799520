import { TextSize, TextWeight } from "@waapi/waap-ui";
import React from "react";
import {
    StyledSpinnerContent,
    StyledSpinnerCoverRing,
    StyledSpinnerPlanet,
    StyledSpinnerRing,
    StyledSpinnerSpots,
    StyledSpinnerText,
} from "./style.js";

export const SpinnerText = ({
    children,
    animationState = "playing",
}: {
    children?: string | React.ReactNode;
    animationState?: "playing" | "stopped";
}): JSX.Element => (
    <StyledSpinnerContent>
        <StyledSpinnerPlanet animationState={animationState}>
            <StyledSpinnerRing animationState={animationState} />
            <StyledSpinnerCoverRing />
            <StyledSpinnerSpots animationState={animationState}>
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
            </StyledSpinnerSpots>
        </StyledSpinnerPlanet>
        <StyledSpinnerText weight={TextWeight.BOLD} align="center" size={TextSize["X-SMALL"]}>
            {children}
        </StyledSpinnerText>
    </StyledSpinnerContent>
);
