import { isLoggedInApi } from "@/entities/User/hooks/useUserState/selectors.js";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useIntercom } from "../../../customHooks/useIntercom.js";
import { PrivateRouteProps } from "./types.js";

export const PrivateRoute = ({ children }: PrivateRouteProps): JSX.Element => {
    const isLoggedIn = useSelector(isLoggedInApi);
    const { isIntercomAuthenticated, loginIntercom } = useIntercom();

    useEffect(() => {
        if (isLoggedIn && !isIntercomAuthenticated) {
            loginIntercom();
        }
    }, [isLoggedIn, isIntercomAuthenticated]);

    if (!isLoggedIn) {
        return <Navigate to="/login" />;
    }

    return children;
};
