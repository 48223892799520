import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[32]};
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${(props) => props.theme.spacing[16]};
`;

export const ContainerRules = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[24]};
`;

export const ListRules = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[16]};
`;

export const ItemRule = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${(props) => props.theme.spacing[12]};
    align-items: center;
`;

export const FooterButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: ${(props) => props.theme.spacing[12]};
`;
