import { Routes } from "react-router-dom";
import { RoutesTypes } from "../../../types/routes.types.js";
import { buildRoutes } from "../../../utils/buildRoutes.utils.js";
import { LinksCreator } from "./LinksCreator/Loadable.js";

const linksCreatorRoutes: RoutesTypes = [
    {
        path: "/",
        element: <LinksCreator />,
    },
];

export const LinksCreatorRoutes = (): JSX.Element => {
    return <Routes>{buildRoutes(linksCreatorRoutes)}</Routes>;
};
