import styled from "styled-components";

export const StyledDivLoading = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    margin: auto;
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    min-height: 0;
`;
