import { CopyMyAffiliationCode } from "@/app/components/CopyMyAffiliationCode/index.js";
import { MyAvatar } from "@/app/components/MyAvatar/index.js";
import { useLinks } from "@/entities/Link/hooks/useLinks/index.js";
import { Button, Popover, PopoverUtils, TextSize, TextWeight } from "@waapi/waap-ui";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCopyToClipboard } from "../../../customHooks/useCopyToClipboard.js";
import { useTranslation } from "../../../customHooks/useTranslation/index.js";
import { getMyIdentity, getUser } from "../../../entities/User/hooks/useUserState/selectors.js";
import { DropdownActions, DropdownActionsProps } from "../../components/DropdownActions/index.js";
import { ReactComponent as HeaderMask } from "./mask.svg";
import { HeaderDropdownIcon, StyledDropdownDiv, StyledHeader, StyledHeaderInner, StyledUserMenu, UserName } from "./style.js";

export const Header = (): JSX.Element => {
    const { t, translations } = useTranslation();
    const identity = useSelector(getMyIdentity);
    const user = useSelector(getUser);
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const popoverRef = useRef<PopoverUtils>(null);
    const { links, loading } = useLinks({ filters: {} });

    const actionsDropdown: DropdownActionsProps["actions"] = {
        billing: {
            label: t(translations.header.actions.billing()),
            fn: () => {
                navigate("/settings/bills");
                if (popoverRef?.current) popoverRef.current.close();
            },
            icon: "billFilled",
        },
        settings: {
            label: t(translations.header.actions.settings()),
            fn: () => {
                navigate("/settings/my-account");
                if (popoverRef?.current) popoverRef.current.close();
            },
            icon: "settings",
        },
        myAccount: {
            label: t(translations.header.actions.myAccount()),
            fn: () => {
                navigate("/settings/my-account");
                if (popoverRef?.current) popoverRef.current.close();
            },
            icon: "avatar",
        },
        logout: {
            label: t(translations.header.actions.logout()),
            fn: () => {
                navigate("/logout");
                if (popoverRef?.current) popoverRef.current.close();
            },
            icon: "export",
        },
    };

    const { copyToClipboard } = useCopyToClipboard();

    const copyDefaultLink = () => {
        const landingLink = links.find((link) => link.tag === "landing");
        if (landingLink) {
            copyToClipboard({
                textToCopy: landingLink.shortUrl,
                textFailed: t(translations.header.shareLink.failed()),
                textSuccess: t(translations.header.shareLink.success()),
            });
        }
    };

    return (
        <>
            <HeaderMask />
            <StyledHeader>
                <StyledHeaderInner>
                    <Button loading={loading} iconLeft="share" size="small" onClick={copyDefaultLink}>
                        {t(translations.header.shareLink.button())}
                    </Button>
                    {user?.affiliation?.tracking && <CopyMyAffiliationCode affiliationCode={user?.affiliation?.tracking} />}
                    <Popover
                        onOpenChange={setIsDropdownOpen}
                        dropdownContent={<DropdownActions actions={actionsDropdown} noDivider />}
                        ref={popoverRef}
                    >
                        <StyledUserMenu isDropdownOpen={isDropdownOpen}>
                            <MyAvatar size="small" />
                            <UserName size={TextSize.SMALL} weight={TextWeight.BOLD}>
                                {identity}
                            </UserName>
                            <StyledDropdownDiv>
                                <HeaderDropdownIcon icon="arrowDown" size="small" />
                            </StyledDropdownDiv>
                        </StyledUserMenu>
                    </Popover>
                </StyledHeaderInner>
            </StyledHeader>
        </>
    );
};
