import { ITheme, Text, TextSize, TextWeight } from "@waapi/waap-ui";
import { useTheme } from "styled-components";
import { Container, NavItem } from "./style.js";
import { MenuItemProps } from "./types.js";

export const MenuItem = ({ icon, label, selected, value, onClick }: MenuItemProps): JSX.Element => {
    const theme = useTheme() as ITheme;

    return (
        <NavItem label={value} selected={selected} onClick={onClick}>
            <Container selected={selected}>
                {icon}
                <Text color={theme.grayscale[100]} size={TextSize["X-SMALL"]} weight={TextWeight.SEMIBOLD}>
                    {label}
                </Text>
            </Container>
        </NavItem>
    );
};
