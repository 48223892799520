import styled from "styled-components";

export const StyledFakeIntercom = styled.div`
    position: fixed;
    z-index: 2147483001;
    width: 0;
    height: 0;
    > div {
        position: fixed;
        z-index: 2147483003;
        bottom: 20px;
        right: 20px;
        width: 60px;
        height: 60px;
        border-radius: ${({ theme }) => theme.radius.round};
        background: #315ae7;
        cursor: pointer;
        box-shadow: 0 1px 6px 0 rgb(0 0 0 / 6%), 0 2px 32px 0 rgb(0 0 0 / 16%);
        > div {
            opacity: 1;
            transform: rotate(0deg) scale(1);
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 60px;
            height: 60px;
            transition: transform 100ms linear, opacity 80ms linear;
            > svg {
                width: 28px;
                height: 32px;
                > path {
                    fill: rgb(255, 255, 255);
                }
            }
        }
    }
`;

export const StyledFakeIntercomModalContent = styled.div`
    text-align: center;
    > svg {
        margin-top: ${({ theme }) => theme.spacing[32]};
        max-width: 10rem;
        max-height: 10rem;
    }
    > h2 {
        margin-top: ${({ theme }) => theme.spacing[16]};
    }
`;

export const StyledModalButtons = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing[16]};
    margin-top: ${({ theme }) => theme.spacing[48]};
    justify-content: center;
    > button {
        flex: 0 0 auto;
        min-width: 10rem;
    }
`;

export const StyledHighlightedText = styled.span`
    color: ${({ theme }) => theme.colors.primary[100]};
`;
