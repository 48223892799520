import { CustomTFunction, TranslationDictionary } from "@/customHooks/useTranslation/types.js";
import { NUMBER_PAYING_AFFILIATES_FOR_TRANSFER, OTHER_COUNTRIES_CODE } from "@waapi/palpatine-client";
import { Trans } from "react-i18next";
import { GoldenRulesTypes } from "./types.js";

export const getGoldenRules = ({
    t,
    translations,
    language,
}: {
    t: CustomTFunction;
    translations: TranslationDictionary;
    language?: string;
}): GoldenRulesTypes => [
    {
        name: "rulePaid",
        text: (
            <Trans
                components={{ strong: <strong /> }}
                i18nKey={
                    language === OTHER_COUNTRIES_CODE
                        ? t(translations.goldenRules.rules.ruleXPaidAffiliates(), { count: +NUMBER_PAYING_AFFILIATES_FOR_TRANSFER })
                        : t(translations.goldenRules.rules.ruleOnePaidAffiliate())
                }
            />
        ),
    },
    {
        name: "rule1",
        text: <Trans components={{ strong: <strong /> }} i18nKey={t(translations.goldenRules.rules.rule1())} />,
    },
    {
        name: "rule2",
        text: <Trans components={{ strong: <strong /> }} i18nKey={t(translations.goldenRules.rules.rule2())} />,
    },
    {
        name: "rule3",

        text: <Trans components={{ strong: <strong /> }} i18nKey={t(translations.goldenRules.rules.rule3())} />,
    },
    {
        name: "rule4",

        text: <Trans components={{ strong: <strong /> }} i18nKey={t(translations.goldenRules.rules.rule4())} />,
    },
    {
        name: "rule5",

        text: <Trans components={{ strong: <strong /> }} i18nKey={t(translations.goldenRules.rules.rule5())} />,
    },
    {
        name: "rule7",

        text: <Trans components={{ strong: <strong /> }} i18nKey={t(translations.goldenRules.rules.rule7())} />,
    },
    {
        name: "rule6",

        text: <Trans components={{ strong: <strong /> }} i18nKey={t(translations.goldenRules.rules.rule6())} />,
    },
];
