import { NavItem as InnerNavItem } from "@waapi/waap-ui";
import styled from "styled-components";
import { MenuItemProps } from "./types.js";

export const NavItem = styled(InnerNavItem)`
    width: 100%;
`;

export const Container = styled.div<Pick<MenuItemProps, "selected">>`
    display: flex;
    align-items: center;
    gap: ${(props) => props.theme.spacing[16]};
    cursor: pointer;
    padding-right: ${(props) => props.theme.spacing[32]};
`;
