import { Input, InputFeedback, Text, TextSize, TextWeight } from "@waapi/waap-ui";
import React from "react";
import { FieldContent } from "./style.js";
import { BaseInputProps } from "./types.js";

export const BaseInput = ({ labelInfos, feedback, fullWidth, ...attributes }: BaseInputProps): JSX.Element => {
    return (
        <FieldContent fullWidth={fullWidth} gap="2" direction="column">
            {labelInfos && (
                <Text color={labelInfos.color} size={TextSize["X-SMALL"]} weight={TextWeight.SEMIBOLD}>
                    {labelInfos.text}
                </Text>
            )}

            <Input {...attributes} />

            {feedback && <InputFeedback {...feedback} />}
        </FieldContent>
    );
};
