import { EnvWarningContext } from "@/customHooks/useEnvWarningToast/context.js";
import { useEnvWarningToast } from "@/customHooks/useEnvWarningToast/useEnvWarningToast.js";
import { useMemo } from "react";

type Props = {
    children: React.ReactNode;
};
export const EnvWarningProvider = ({ children }: Props) => {
    const { warned } = useEnvWarningToast();
    const value = useMemo(() => {
        return { warned };
    }, [warned]);
    return <EnvWarningContext.Provider value={value}>{children}</EnvWarningContext.Provider>;
};
