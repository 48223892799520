import { useAccountRequirements } from "@/entities/Account/hooks/useAccountRequirements/index.js";
import { actions } from "@/entities/User/hooks/useUserState/slice.js";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const StripeRequirementsChecker = (): JSX.Element => {
    const { hasRequirements, loading } = useAccountRequirements();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!loading) dispatch(actions.setHasRequirements(hasRequirements));
    }, [hasRequirements, loading]);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
};
