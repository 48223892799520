import useRequest from "@/customHooks/useRequest.js";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "redux-injectors";
import { CHROME_EXTENSION_ID } from "utils/constants.js";
import { retryPromise } from "utils/retryPromise.js";
import { isNavigatorValidAndWithAnExtension } from "../../../../utils/validateNavigator.js";
import { TOKEN_COOKIE_KEY } from "./constants.js";
import { getUserId, getUserLanguage, isCurrentlyImpersonating as isCurrentlyImpersonatingSelector, isLoadingUser } from "./selectors.js";
import { actions, reducer, sliceKey } from "./slice.js";
import { SecondaryAccountType, UseUserStateReturnType } from "./types.js";

export const useInitUserState = () => {
    useInjectReducer({ key: sliceKey, reducer });
    const [wantToReconnect, setWantToReconnect] = useState<boolean | undefined>(undefined);

    const tryToReconnect = () => {
        retryPromise(
            async () => {
                const response = await (global as any).chrome.runtime.connect(CHROME_EXTENSION_ID, { action: "GLOBAL/READY_TO_CONNECT" });
                if (response === true) {
                    setWantToReconnect(true);
                }
                return response;
            },
            1000,
            20,
            [],
            [undefined, null],
        );
    };

    useEffect(() => {
        if (isNavigatorValidAndWithAnExtension()) {
            const port = window.chrome.runtime.connect(CHROME_EXTENSION_ID, {
                name: "store",
            });

            port.onDisconnect.addListener(() => {
                setWantToReconnect(true);
                tryToReconnect();
            });
        }
    }, [wantToReconnect]);
};

export const useUserState = (params: { loadUser?: boolean }): UseUserStateReturnType => {
    useInjectReducer({ key: sliceKey, reducer });
    const { loadUser = true } = params;
    const dispatch = useDispatch();
    const isLoading = useSelector(isLoadingUser);
    const isCurrentlyImpersonating = useSelector(isCurrentlyImpersonatingSelector);
    const ambassadorLanguage = useSelector(getUserLanguage);
    const userId = useSelector(getUserId);

    const getProfile = useRequest<"getProfile">("getProfile");

    const setLanguage = (language: string) => {
        dispatch(actions.setLanguage(language));
    };

    const fetchUser = async () => {
        if (isLoading) return;
        try {
            const authToken = Cookies.get(TOKEN_COOKIE_KEY);
            if (!authToken) return;

            dispatch(actions.updateUserState({ isLoadingUser: true }));

            const res = await getProfile();
            if (res.hasFailed) throw new Error("Failed to fetch user");

            const { ambassador, isImpersonatedAmbassador } = res.data;
            if (isImpersonatedAmbassador) {
                dispatch(
                    actions.updateUserState({
                        auth: {
                            secondaryAccount: {
                                apiToken: authToken,
                                user: ambassador,
                                hasRequirements: false,
                                type: SecondaryAccountType.IMPERSONATION,
                            },
                        },
                        isLoadingUser: false,
                    }),
                );
            } else {
                dispatch(
                    actions.updateUserState({
                        auth: { rootAccount: { apiToken: authToken, user: ambassador, hasRequirements: false } },
                        isLoadingUser: false,
                    }),
                );
            }
        } catch {
            dispatch(
                actions.updateUserState({
                    isLoadingUser: false,
                }),
            );
        }
    };

    useEffect(() => {
        if (loadUser) {
            fetchUser();
        }
    }, [loadUser]);

    return { loading: isLoading, isCurrentlyImpersonating, language: ambassadorLanguage, userId, setLanguage };
};
