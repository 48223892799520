import { useNavigate, Navigate, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { StyledDivLoading } from "../../../../styles/globalStyles.js";
import { SpinnerText } from "../../../components/SpinnerText/index.js";
import useRequest from "../../../../customHooks/useRequest.js";
import { useUserManager } from "../../../../entities/User/hooks/useUserManager/index.js";

export const Impersonate = (): JSX.Element => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const lifelineToken = new URLSearchParams(search).get("lifelineToken");
    const ambassadorId = new URLSearchParams(search).get("ambassadorId");
    const impersonateAmbassador = useRequest<"impersonateAmbassador">("impersonateAmbassador");
    const { impersonate: afterImpersonate } = useUserManager();

    if (!lifelineToken || !ambassadorId) return <Navigate to="/" />;

    const handleImpersonate = async (): Promise<void> => {
        const impersonateResponse = await impersonateAmbassador({ Authorization: `Bearer ${lifelineToken}` })({ pathParams: { ambassadorId } });
        if (impersonateResponse.hasFailed) navigate("/");
        else
            afterImpersonate({
                user: impersonateResponse.data.ambassador,
                token: impersonateResponse.data.authToken,
            });
    };

    useEffect(() => {
        handleImpersonate();
    }, []);

    return (
        <StyledDivLoading>
            <SpinnerText animationState="playing">Impersonate</SpinnerText>
        </StyledDivLoading>
    );
};
