import sendMessage from "@/utils/chromeMessages.js";

export function versionCheck(): Promise<string | null> {
    return sendMessage<string>({
        action: "GLOBAL/VERSION_CHECK",
    })
        .then((extVersion) => {
            return extVersion || null;
        })
        .catch(() => {
            return null;
        });
}
