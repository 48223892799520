import { Tooltip } from "@waapi/waap-ui";
import { useTranslation } from "customHooks/useTranslation/index.js";
import { LargeCustomizableButton } from "./LargeCustomizableButton/index.js";
import { SmallCustomizableButton } from "./SmallCustomizableButton/index.js";
import { FeedbackContainer } from "./styles.js";
import { IntercomFeedbackProps } from "./types.js";

export const CustomizableButton = ({ size }: IntercomFeedbackProps) => {
    const { t, translations } = useTranslation();

    return (
        <FeedbackContainer>
            {size === "large" ? (
                <LargeCustomizableButton />
            ) : (
                <Tooltip content={t(translations.intercom_feedback.tooltip())}>
                    <SmallCustomizableButton />
                </Tooltip>
            )}
        </FeedbackContainer>
    );
};
