import { Avatar, Icon } from "@waapi/waap-ui";
import type { Properties as CSSProperties } from "csstype";
import styled from "styled-components";

export const StyledAvatar = styled(Avatar)``;

export const StyledAvatarContainer = styled.div<{ showSpinner?: boolean }>`
    position: relative;
    height: 1.875rem;
    width: 1.875rem;
    &::after {
        content: "";
        position: absolute;
        top: -2px;
        left: -2px;
        bottom: -2px;
        right: -2px;
        border-radius: ${({ theme }) => theme.radius.round};
        border: 4px solid transparent;
        border-right: 4px solid ${({ theme }) => theme.colors.primary[100]};
        transform: rotateZ(0);
        animation: ${({ showSpinner }) => (showSpinner ? `spin 1.5s linear infinite` : undefined)};
        opacity: ${({ showSpinner }) => (showSpinner ? 1 : 0)};
        height: calc(100% + 4px);
        width: calc(100% + 4px);
    }

    @keyframes spin {
        0% {
            transform: rotateZ(0turn);
        }
        100% {
            transform: rotateZ(1turn);
        }
    }
`;

export const StyledAction = styled.span<{ disabled: boolean }>`
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 0.375rem 0.5rem 0.375rem 0.75rem;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 100%;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    outline: none;
    color: ${(props) => (props.disabled ? props.theme.grayscale[70] : "inherit")};
    z-index: ${({ theme: { elevationLevels } }) => elevationLevels.nine};
    border-radius: ${({ theme }) => theme.radius.xs};
    overflow-y: hidden;
    &:hover {
        background-color: ${(props) => (props.disabled ? "inherit" : props.theme.grayscale[20])};
    }
    > :first-child + span {
        margin-left: 0.5rem;
    }
`;

type StyledDropdownActionsProps = {
    maxHeight: CSSProperties["maxHeight"];
    maxWidth: CSSProperties["maxWidth"];
};

export const StyledDropdownActions = styled.div<StyledDropdownActionsProps>`
    display: flex;
    flex-direction: column;
    margin: 0 -0.25rem;
    max-width: ${({ maxWidth }) => maxWidth};
    max-height: ${({ maxHeight }) => maxHeight};
    overflow-x: ${({ maxWidth }) => (maxWidth !== undefined ? "hidden" : undefined)};
    & img[alt="crown"] {
        width: 0.75rem;
        height: 0.75rem;
    }
    ${StyledAction} {
        max-width: ${({ maxWidth }) => (maxWidth !== undefined ? "100%" : undefined)};
        overflow-x: ${({ maxWidth }) => (maxWidth !== undefined ? "hidden" : undefined)};
    }
`;

export const StyledActionsSectionTitle = styled.div`
    padding: 0.25rem 0.75rem 0.25rem 0.5rem;
    color: ${(props) => props.theme.grayscale[70]};
`;

type StyledTextActionProps = {
    isBold: boolean;
    maxWidth: CSSProperties["maxWidth"];
};

export const StyledTextAction = styled.span<StyledTextActionProps>`
    font-size: 1rem;
    font-weight: ${(props) => (props.isBold ? "bold" : "normal")};
    flex: 1 1 100%;
    text-overflow: ${({ maxWidth }) => (maxWidth !== undefined ? "ellipsis" : undefined)};
    white-space: ${({ maxWidth }) => (maxWidth !== undefined ? "nowrap" : undefined)};
    overflow-x: ${({ maxWidth }) => (maxWidth !== undefined ? "hidden" : undefined)};
`;

export const StyledSeparator = styled.div`
    align-self: center;
    width: calc(100%);
    border-bottom: 1px solid ${(props) => props.theme.grayscale[50]};
`;

export const StyledIcon = styled(Icon)<{ iconColor?: string }>`
    height: 1rem;
    width: 1rem;
    color: ${(props) => props.iconColor ?? "inherit"};
    svg {
        height: 100%;
        width: 100%;
    }
`;

export const StyledActionsContainer = styled.div<{
    maxHeight?: CSSProperties["maxHeight"];
}>`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding-right: ${(props) => props.theme.spacing[4]};
    overflow-y: ${({ maxHeight }) => (maxHeight !== undefined ? "auto" : undefined)};
`;
