import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ImportView } from "./constants.js";
import { ContainerState } from "./types.js";

// The initial state of the ProspectList container
export const initialState: ContainerState = {};

export const initialKeyState: ContainerState[keyof ContainerState] = {
    hasError: false,
    maxProspectCount: 1000,
    importId: undefined,
    view: undefined,
    toastId: undefined,
    listData: {
        prospectList: null,
        loading: false,
    },
    isHidden: false,
    importProgression: null,
    user: undefined,
    importLimits: null,
};

const useImportStateSlices = createSlice({
    name: "useImportStateState",
    initialState,
    reducers: {
        setUseImportState(
            state,
            action: PayloadAction<
                {
                    importId: string;
                } & Partial<ContainerState[keyof ContainerState]>
            >,
        ) {
            const { importId, ...payload } = action.payload;
            state[importId] = { ...state[importId], ...payload };
        },
        setImportId(state, action: PayloadAction<{ key: string; importId: string }>) {
            state[action.payload.key].importId = action.payload.importId;
        },
        init(state, action: PayloadAction<string>) {
            state[action.payload] = initialKeyState;
        },
        reset(state, action: PayloadAction<string>) {
            state[action.payload] = initialKeyState;
        },
        setIsHidden(state, action: PayloadAction<{ key: string; isHidden: boolean }>) {
            state[action.payload.key].isHidden = action.payload.isHidden;
        },
        setLoadingProspectList(state, action: PayloadAction<{ key: string; loading: boolean }>) {
            state[action.payload.key].listData.loading = action.payload.loading;
        },
        setProspectList(state, action: PayloadAction<{ key: string; prospectListData: ContainerState[keyof ContainerState]["listData"] }>) {
            state[action.payload.key].listData = action.payload.prospectListData;
        },
        start(state, action: PayloadAction<string>) {
            state[action.payload] = { ...initialKeyState, view: ImportView.IMPORT_STARTED };
        },
        progress(state, action: PayloadAction<string>) {
            state[action.payload] = {
                ...(state[action.payload] !== undefined ? state[action.payload] : initialKeyState),
                view: ImportView.IMPORT_PROGRESSION,
            };
        },
        done(state, action: PayloadAction<string>) {
            state[action.payload] = { ...initialKeyState, view: ImportView.IMPORT_STOPPED };
        },
        hasError(state, action: PayloadAction<string>) {
            state[action.payload] = { ...initialKeyState, hasError: true };
        },
        setMaxProspectCount(state, action: PayloadAction<{ listId: string; maxProspectCount: number }>) {
            state[action.payload.listId] = {
                ...(state[action.payload.listId] !== undefined ? state[action.payload.listId] : initialKeyState),
                maxProspectCount: action.payload.maxProspectCount,
            };
        },
    },
});

export const { actions, reducer, name: sliceKey } = useImportStateSlices;
