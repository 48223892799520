import React, { useEffect } from "react";
import { useUserManager } from "../../../../entities/User/hooks/useUserManager/index.js";

export const Logout = (): JSX.Element => {
    const userManager = useUserManager();

    useEffect(() => {
        userManager.logout();
    }, []);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
};
