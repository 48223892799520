import { ITheme } from "@waapi/waap-ui";
import styled from "styled-components";

export const getSelectStyles = ({ variant, theme }: { variant: "dark" | "light"; theme: ITheme }) => ({
    container: (provided) => ({
        ...provided,
        flex: 1,
        zIndex: 20,
    }),
    singleValue: (provided) => ({
        ...provided,
        color: variant === "light" ? theme.grayscale[5] : theme.grayscale[100],
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        padding: "0px",
        color: variant === "light" ? theme.grayscale[5] : theme.grayscale[100],
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: variant === "light" ? theme.grayscale[5] : theme.grayscale[100],
    }),
});

export const StyledLanguageSelect = styled.div<{ size: "small" | "large" }>`
    & > div > div {
        background-color: transparent;
        &:first-of-type {
            flex-wrap: nowrap;
            max-height: 38px;
        }
    }
    [class$="MenuList"] {
        overflow-x: hidden;
    }
    [class$="option"][tabindex] > div {
        white-space: nowrap;
    }
`;

export const StyledLangOptionLabel = styled.div<{ size: "small" | "large" }>`
    margin: auto;
    display: flex;
    align-items: center;
    & > svg {
        height: 1.1em;
        width: 1.1em;
    }
    & > span {
        margin-left: 1em;
    }
`;

export const ValueContainerContent = styled.div<{ size: "small" | "large" }>`
    width: 100%;
    display: flex;
    justify-content: ${(props) => (props.size === "large" ? "flex-start" : "center")};
`;
