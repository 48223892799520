import { Button, ITheme, TextSize, TextWeight } from "@waapi/waap-ui";
import { useTranslation } from "customHooks/useTranslation/index.js";
import { useTheme } from "styled-components";
import { StyledCardBody, StyledContainer, StyledHeaderBody, StyledIconButton, StyledText, StyledTextContainer, SvgContainer } from "./styles.js";

export const LargeCustomizableButton = (): JSX.Element => {
    const { t, translations } = useTranslation();
    const theme = useTheme() as ITheme;

    return (
        <StyledContainer>
            <StyledCardBody>
                <StyledHeaderBody>
                    <SvgContainer>
                        <a href={t(translations.intercom_feedback.icon_link())} target="_blank" rel="noreferrer">
                            <StyledIconButton icon="filledSendMessage" iconColor="white" size="small" />
                        </a>
                    </SvgContainer>
                    <StyledTextContainer>
                        <StyledText size={TextSize["X-SMALL"]} weight={TextWeight.BOLD} align="left">
                            {t(translations.intercom_feedback.title())}
                        </StyledText>
                        <StyledText size={TextSize["XX-SMALL"]} color={theme.grayscale[70]} align="left">
                            {t(translations.intercom_feedback.subtitle())}
                        </StyledText>
                    </StyledTextContainer>
                </StyledHeaderBody>
                <a href={t(translations.intercom_feedback.link())} target="_blank" rel="noreferrer">
                    <Button size="small" variant="tinted">
                        {t(translations.intercom_feedback.button())}
                    </Button>
                </a>
            </StyledCardBody>
        </StyledContainer>
    );
};
