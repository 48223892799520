import { useToasts as innerUseToasts } from "@waapi/waap-ui";
import { useTranslation } from "./useTranslation/index.js";

type InnerAddToastParameters = Parameters<ReturnType<typeof innerUseToasts>["addToast"]>;
type InnerAddToastReturnType = ReturnType<ReturnType<typeof innerUseToasts>["addToast"]>;

type UseToastReturnType = Omit<ReturnType<typeof innerUseToasts>, "addToast"> & {
    addToast: (
        content: InnerAddToastParameters[0],
        options?: InnerAddToastParameters[1] & { errorCode?: string },
        callback?: InnerAddToastParameters[2],
    ) => InnerAddToastReturnType;
    addErrorToast: (component?: JSX.Element | string, errorCode?: string) => InnerAddToastReturnType;
};

const useToasts = (): UseToastReturnType => {
    const { addToast: innerAddToast, ...innerHook } = innerUseToasts();
    const { t, translations } = useTranslation();

    const addToast = (...args: Parameters<UseToastReturnType["addToast"]>): InnerAddToastReturnType => {
        const [content, options = {}, callback] = args;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { errorCode, ...innerOptions } = options;

        innerAddToast(content, innerOptions, callback);
    };

    const addErrorToast = (...args: Parameters<UseToastReturnType["addErrorToast"]>): InnerAddToastReturnType => {
        const [component] = args;
        const errorComponent = component ?? t(translations.error());
        innerAddToast(errorComponent, { appearance: "error", autoDismiss: true });
    };

    return { ...innerHook, addToast, addErrorToast };
};

export default useToasts;
