import { AuthPage } from "@/app/components/AuthPage/index.js";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import React from "react";
import { LoginForm } from "./LoginForm/index.js";

export const Login = (): JSX.Element => {
    const { t, translations } = useTranslation();

    return (
        <AuthPage
            title={translations.pages.login.title()}
            subtitle={{ text: translations.pages.login.subtitle(), link: { children: t(translations.pages.login.subtitle_link()), to: "/signup" } }}
            form={<LoginForm />}
        />
    );
};
