import useToasts from "./useToasts.js";

export function useCopyToClipboard() {
    const { addToast } = useToasts();

    const copyToClipboard = ({ textToCopy, textSuccess, textFailed }: { textToCopy: string; textSuccess: string; textFailed: string }) => {
        navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
                addToast(textSuccess, { appearance: "success", id: "copyToClipboard", autoDismiss: true });
            })
            .catch(() => {
                addToast(textFailed, { appearance: "error", id: "copyToClipboard", autoDismiss: true });
            });
    };

    return { copyToClipboard };
}
