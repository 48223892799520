import { Button, Modal, Text, TextSize, TextWeight, useModal } from "@waapi/waap-ui";
import { Trans } from "react-i18next";
import { useTheme } from "styled-components";
import { ReactComponent as ShockedAlienSvg } from "../../../assets/surprised-waalien.svg";
import { useTranslation } from "../../../customHooks/useTranslation/index.js";
import { StyledFakeIntercom, StyledFakeIntercomModalContent, StyledHighlightedText, StyledModalButtons } from "./style.js";

export const FakeIntercom = (): JSX.Element => {
    const { t, translations } = useTranslation();
    const theme = useTheme();

    const [isOpen, toggle] = useModal({ name: "intercom-add-blocker-modal", useUrl: false, defaultIsOpen: false });

    const onClick = () => {
        toggle(true);
    };

    return (
        <>
            <StyledFakeIntercom>
                <div
                    onClick={onClick}
                    role="button"
                    tabIndex={0}
                    aria-live="polite"
                    onKeyDown={(event) => {
                        if (event.key === "Enter" || event.key === "Space") onClick();
                    }}
                >
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32">
                            <path d="M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15 6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z" />
                        </svg>
                    </div>
                </div>
            </StyledFakeIntercom>
            <Modal isOpen={isOpen} onClose={() => toggle(false)} fluid={false}>
                <StyledFakeIntercomModalContent>
                    <ShockedAlienSvg />
                    <Text size={TextSize.LARGE} weight={TextWeight.BOLD}>
                        {t(translations.fake_intercom_modal.whoops())}
                    </Text>
                    <br />
                    <Text color={theme.grayscale[80]} size={TextSize.SMALL}>
                        {t(translations.fake_intercom_modal.text_1())}
                    </Text>
                    <br />
                    <Text color={theme.grayscale[80]} size={TextSize.SMALL} weight={TextWeight.BOLD}>
                        <Trans
                            i18nKey={translations.fake_intercom_modal.text_2()}
                            components={{ strong: <StyledHighlightedText /> }}
                            values={{
                                appName: window.location.origin,
                            }}
                        />
                    </Text>
                    <br />
                    <Text color={theme.grayscale[80]} size={TextSize.SMALL}>
                        {t(translations.fake_intercom_modal.text_3())}
                    </Text>
                    <StyledModalButtons>
                        <Button onClick={() => toggle(false)}>{t(translations.fake_intercom_modal.got_it())}</Button>
                    </StyledModalButtons>
                </StyledFakeIntercomModalContent>
            </Modal>
        </>
    );
};
