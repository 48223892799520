import { Breakpoint } from "@waapi/waap-ui";

export const BODY_WIDTH_MAP: Record<Breakpoint, string> = {
    [Breakpoint.XS]: "90%",
    [Breakpoint.SM]: "90%",
    [Breakpoint.MD]: "70%",
    [Breakpoint.LG]: "70%",
    [Breakpoint.XL]: "50%",
    [Breakpoint.XXL]: "40%",
};
