import { Routes } from "react-router-dom";
import { RoutesTypes } from "../../../types/routes.types.js";
import { buildRoutes } from "../../../utils/buildRoutes.utils.js";
import { MyAccount } from "./MyAccount/Loadable.js";
import { MyBills } from "./MyBills/Loadable.js";
import { MyDocuments } from "./MyDocuments/Loadable.js";
import { Container } from "./style.js";

const settingsRoutes: RoutesTypes = [
    {
        path: "/",
        element: <MyAccount />,
    },
    {
        path: "/my-account",
        element: <MyAccount />,
    },
    {
        path: "/bills",
        element: <MyBills />,
    },
    {
        path: "/documents",
        element: <MyDocuments />,
    },
];

export const SettingsRoutes = (): JSX.Element => {
    return (
        <Container>
            <Routes>{buildRoutes(settingsRoutes)}</Routes>
            {/* @INFO: this br is used to have a padding at the end of the page */}
            <br />
        </Container>
    );
};
