export enum SettingsSecondaryMenuPage {
    MY_ACCOUNT = "my-account",
    BILLS = "bills",
    DOCUMENTS = "documents",
}

export type SettingsSecondaryMenuItemProperties = {
    icon: React.ReactNode;
    label: string;
    hasNotification?: boolean;
};
