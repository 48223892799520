import { ImportView } from "@/customHooks/useImportState/constants.js";
import { ImportDone } from "../ImportDone/index.js";
import { ImportProgression } from "../ImportProgression/index.js";
import { ImportViewWrapper } from "./styles.js";
import { ImportViewProps } from "./types.js";

export const ImportViewSwitcher = ({ listData, view, progression, maxProspectCount, onStopImport }: ImportViewProps) => {
    switch (view) {
        case ImportView.IMPORT_STOPPED:
            return <ImportDone listData={listData} progression={progression} maxProspectCount={maxProspectCount} />;
        case ImportView.IMPORT_STARTED:
        case ImportView.IMPORT_PROGRESSION:
            return <ImportProgression onStopImport={onStopImport} progression={progression} maxProspectCount={maxProspectCount} />;
        default:
            return null;
    }
};

export const ImportViewDisplay = (props: ImportViewProps) => {
    return (
        <ImportViewWrapper>
            <ImportViewSwitcher {...props} />
        </ImportViewWrapper>
    );
};
