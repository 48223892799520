import { IAffiliationError, AffiliationErrorTypeEnum } from "@waapi/palpatine-client";

export const groupAffiliationErrorsByErrorType = (affiliationErrors: IAffiliationError[]): Record<AffiliationErrorTypeEnum, IAffiliationError[]> => {
    return affiliationErrors.reduce<Record<AffiliationErrorTypeEnum, IAffiliationError[]>>(
        (acc, affiliationError) => {
            if (!acc[affiliationError.type]) acc[affiliationError.type] = [];
            acc[affiliationError.type].push(affiliationError);
            return acc;
        },
        {
            [AffiliationErrorTypeEnum.SAME_COMPANY]: [],
        },
    );
};
