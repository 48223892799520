import styled from "styled-components";
import { OnboardingPageProps } from "./types.js";

export const Container = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    background: ${(props) => props.theme.gradients.blueToPurple};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${(props) => props.theme.spacing[32]};
    color: ${(props) => props.theme.grayscale.white};
    padding: ${(props) => props.theme.spacing[32]};
`;

export const Content = styled.div<Pick<OnboardingPageProps, "maxWidth">>`
    display: flex;
    gap: ${(props) => props.theme.spacing[32]};
    max-width: ${(props) => props.maxWidth ?? "100%"};
`;

export const Logo = styled.img`
    height: 6rem;
    width: 6rem;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[32]};
`;

export const Titles = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[12]};
`;

export const LogoutButtonContainer = styled.div`
    position: absolute;
    top: 1em;
    right: 1em;
`;
