import { GetIntercomIdentifiersResponse200 as UserIntercomIdentifiers } from "@waapi/palpatine-client";
import qs from "query-string";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getIntercomIdentifiers } from "../entities/User/hooks/useUserState/selectors.js";
import { actions } from "../entities/User/hooks/useUserState/slice.js";
import { IN_PRODUCTION } from "../utils/constants.js";
import { retryPromise } from "../utils/retryPromise.js";
import useRequest from "./useRequest.js";
import { useTranslation } from "./useTranslation/index.js";

const ADD_BLOCKER_CHECKER_INTERVAL = 2000;
const ADD_BLOCKER_CHECKER_TIMEOUT = 10000;

const bootIntercom = (params: { appId: string; intercomIdentifiers?: UserIntercomIdentifiers }) => {
    const { appId, intercomIdentifiers } = params;
    if ("Intercom" in window) {
        window.Intercom("boot", {
            app_id: appId,
            ...(intercomIdentifiers?.intercomUserId
                ? {
                      ...(intercomIdentifiers.intercomUserId.includes("@")
                          ? { email: intercomIdentifiers.intercomUserId }
                          : { user_id: intercomIdentifiers.intercomUserId }),
                      user_hash: intercomIdentifiers.intercomUserHash,
                  }
                : {}),
        });
    } else {
        console.error("Cannot boot intercom");
    }
};

export const useIntercom = (): {
    openIntercom: () => void;
    trackError: (params: { errorCode: string }) => void;
    isIntercomAuthenticated: boolean;
    loginIntercom: () => void;
    logoutIntercom: () => void;
    openIntercomFeedback: () => void;
} => {
    const { t, translations } = useTranslation();
    const { search } = useLocation();
    const [isIntercomAuthenticated, setIsIntercomAuthenticated] = useState<boolean>(false);
    const getMyIntercomIdentifiers = useRequest<"getMyIntercomIdentifiers">("getMyIntercomIdentifiers");
    const dispatch = useDispatch();
    const myIntercomIdentifiers = useSelector(getIntercomIdentifiers);
    const appId = IN_PRODUCTION ? "xmhcwqnf" : "lwo9l26b";

    const getUserIntercomIdentifiers = async (): Promise<UserIntercomIdentifiers | undefined> => {
        if (myIntercomIdentifiers?.intercomUserId) return myIntercomIdentifiers;

        const intercomIdentifiers = await retryPromise(
            async () => {
                const response = await getMyIntercomIdentifiers();

                if (response.hasFailed) {
                    throw new Error("failed to fetch intercom identifiers");
                }

                return response.data;
            },
            5000,
            5,
        );

        if ("errors" in intercomIdentifiers) return undefined;

        dispatch(
            actions.updateUserState({
                intercomIdentifiers,
            }),
        );

        return intercomIdentifiers;
    };

    const shutdownIntercom = () => {
        window.Intercom("shutdown");
    };

    const openIntercom = () => {
        if ("Intercom" in window) {
            window.Intercom("show");
        }
    };

    const trackError = (params: { errorCode: string }) => {
        if ("Intercom" in window) {
            window.Intercom("trackEvent", "got-error", params);
        }
    };

    const openIntercomFeedback = () => {
        if ("Intercom" in window) {
            // @ts-ignore
            window.Intercom("startSurvey", +t(translations.intercom_feedback.survey_id()));
        }
    };

    const loginIntercom = async () => {
        const intercomIdentifiers = await getUserIntercomIdentifiers();

        if (intercomIdentifiers?.intercomUserId) {
            setIsIntercomAuthenticated(true);
        }

        shutdownIntercom();
        bootIntercom({ appId, intercomIdentifiers });
    };

    const logoutIntercom = async () => {
        setIsIntercomAuthenticated(false);
        shutdownIntercom();
    };

    useEffect(() => {
        const parsedSearch = qs.parse(search);
        if (parsedSearch.contact_support !== undefined) openIntercom();
    }, [search]);

    return { openIntercom, trackError, isIntercomAuthenticated, loginIntercom, logoutIntercom, openIntercomFeedback };
};

export const useInitIntercom = (): { isBooted: boolean } => {
    const [isBooted, setIsBooted] = useState<boolean>(false);
    const appId = IN_PRODUCTION ? "xmhcwqnf" : "lwo9l26b";
    const myIntercomIdentifiers = useSelector(getIntercomIdentifiers);

    const shutdownIntercom = () => {
        window.Intercom("shutdown");
    };

    const initIntercom = async () => {
        shutdownIntercom();
        bootIntercom({ appId, intercomIdentifiers: myIntercomIdentifiers });
    };

    useEffect(() => {
        let addBlockerCheckInterval: NodeJS.Timeout;
        let addBlockerCheckTimeout: NodeJS.Timeout;

        initIntercom();

        const checkAddBlocker = async () => {
            try {
                await fetch(`https://widget.intercom.io/widget/${appId}`, {
                    method: "get",
                    mode: "no-cors",
                });
            } catch (e) {
                // prevents doing wrong when network is not available
                // @ts-ignore
                if (navigator?.connection?.type === "none") return;

                setIsBooted(false);
                shutdownIntercom();
                clearInterval(addBlockerCheckInterval);
                clearTimeout(addBlockerCheckTimeout);
            }
        };

        window.Intercom("onUnreadCountChange", () => {
            setIsBooted(true);
            addBlockerCheckInterval = setInterval(checkAddBlocker, ADD_BLOCKER_CHECKER_INTERVAL);
            addBlockerCheckTimeout = setTimeout(() => clearInterval(addBlockerCheckInterval), ADD_BLOCKER_CHECKER_TIMEOUT);
        });

        return () => {
            shutdownIntercom();
            clearInterval(addBlockerCheckInterval);
            clearTimeout(addBlockerCheckTimeout);
        };
    }, [IN_PRODUCTION]);

    return { isBooted };
};
