import { CHROME_EXTENSION_ID } from "./constants.js";
import { isNavigatorValidAndWithAnExtension } from "./validateNavigator.js";

export default function sendMessage<T>(message: any): Promise<T> {
    return new Promise((resolve, reject) => {
        if (isNavigatorValidAndWithAnExtension()) {
            return window.chrome.runtime.sendMessage(CHROME_EXTENSION_ID, message, resolve);
        }
        return reject(new Error(`chrome not available`));
    });
}
