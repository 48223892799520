import { AffiliationErrors } from "@/app/containers/AffiliationErrors/index.js";
import { useImportState } from "@/customHooks/useImportState/index.js";
import { useWaalaxyExtension } from "@/customHooks/useWaalaxyExtension/index.js";
import { useAffiliatesSummaryFromLastLogin } from "@/entities/Afffiliate/hooks/useAffiliatesSummaryFromLastLogin/index.js";
import { getHasAgreedGoldenRules, getUser } from "@/entities/User/hooks/useUserState/selectors.js";
import { ITheme, Container as InnerContainer } from "@waapi/waap-ui";
import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { FlexContainer } from "../styles/globalStyles.js";
import { ChooseAdvantage } from "./components/ChooseAdvantage/index.js";
import { IsStripeAccount } from "./components/IsStripeAccount/index.js";
import { NotFoundPage } from "./components/NotFoundPage/index.js";
import { AccountRequirementsChecker } from "./containers/AccountRequirementsChecker/index.js";
import { GoldenRules } from "./containers/GoldenRules/index.js";
import { Header } from "./containers/Header/index.js";
import { SideBar } from "./containers/Sidebar/index.js";
import { AffiliatesRoutes } from "./pages/AffiliatesRoutes/index.js";
import { DashboardRoutes } from "./pages/DashboardRoutes/index.js";
import { FaqRoutes } from "./pages/FAQRoutes/index.js";
import { LinksCreatorRoutes } from "./pages/LinksCreatorRoutes/index.js";
import { SettingsRoutes } from "./pages/SettingsRoutes/index.js";

export const Container = styled(InnerContainer)`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    min-height: 0;
    gap: ${({ theme }: { theme: ITheme }) => theme.spacing[32]};
    padding-top: ${({ fluid }) => (fluid ? undefined : "5rem")};
`;

export const AuthorizedApp = (): ReactElement => {
    useWaalaxyExtension(true);
    useAffiliatesSummaryFromLastLogin();
    useImportState();

    const user = useSelector(getUser);
    const hasAgreedGoldenRules = useSelector(getHasAgreedGoldenRules);

    if (!user?.affiliation?.contract) return <ChooseAdvantage />;

    return (
        <>
            {!hasAgreedGoldenRules && <GoldenRules />}
            <AffiliationErrors />
            <SideBar />
            <Container>
                <Header />
                <IsStripeAccount>
                    <AccountRequirementsChecker />
                </IsStripeAccount>
                <FlexContainer>
                    <Routes>
                        <Route path="/">
                            <Route index element={<DashboardRoutes />} />
                            <Route path="home/*" element={<DashboardRoutes />} />
                            <Route path="affiliates/*" element={<AffiliatesRoutes />} />
                            <Route path="links/*" element={<LinksCreatorRoutes />} />
                            <Route path="settings/*" element={<SettingsRoutes />} />
                            <Route path="faq/*" element={<FaqRoutes />} />
                            <Route path="*" element={<NotFoundPage />} />
                        </Route>
                    </Routes>
                </FlexContainer>
            </Container>
        </>
    );
};
