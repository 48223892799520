import { AffiliationErrorTypeEnum } from "@waapi/palpatine-client";
import { CustomTFunction, TranslationDictionary } from "@/customHooks/useTranslation/types.js";

export const errorTypeTexts = ({
    t,
    translations,
}: {
    t: CustomTFunction;
    translations: TranslationDictionary;
}): Record<AffiliationErrorTypeEnum, string> => ({
    [AffiliationErrorTypeEnum.SAME_COMPANY]: t(translations.affiliation_error.content.list_error_type.same_company()),
});
