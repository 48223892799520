import { Icon } from "@waapi/waap-ui";
import { Form as InnerForm } from "formik";
import styled from "styled-components";

export const LinkContent = styled.div`
    color: ${(props) => props.theme.colors.primary[100]};
`;

export const Form = styled(InnerForm)`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[32]};
`;

export const InputsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[24]};
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[24]};
`;

export const ShowPasswordIcon = styled(Icon)`
    cursor: pointer;
`;
