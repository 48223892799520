/* eslint-disable import/no-import-module-exports */
import { ApiClientsLoader } from "@/app/ApiClientsLoader.js";
import { UserLoader } from "@/app/UserLoader.js";
import { Theme } from "@waapi/waap-ui";
import FontObserver from "fontfaceobserver";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { MixpanelProvider } from "react-mixpanel-browser";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthenticationLoader } from "./app/AuthenticationLoader.js";
import { ErrorBoundary } from "./app/ErrorBoundary.js";
import { WithTranslationsProvider } from "./app/providers/index.js";
import "./styles/fonts.css";
import { lightTheme } from "./styles/theme.js";
import { configureAppStore } from "./utils/store/configureStore.js";

const store = configureAppStore();
const MOUNT_NODE = document.getElementById("root") as HTMLElement;

const root = createRoot(MOUNT_NODE);

export const ConnectedApp = (): JSX.Element => {
    return (
        <Theme theme={lightTheme}>
            <Provider store={store}>
                <HelmetProvider>
                    <Router>
                        <ErrorBoundary>
                            <MixpanelProvider token={process.env.VITE_PALPATINE_MIXPANEL_TOKEN}>
                                <ApiClientsLoader>
                                    <UserLoader>
                                        <WithTranslationsProvider>
                                            <AuthenticationLoader />
                                        </WithTranslationsProvider>
                                    </UserLoader>
                                </ApiClientsLoader>
                            </MixpanelProvider>
                        </ErrorBoundary>
                    </Router>
                </HelmetProvider>
            </Provider>
        </Theme>
    );
};
const render = (): void => {
    root.render(<ConnectedApp />);
};

const bodyFont400 = new FontObserver("Helvetica Neue", { weight: 400 });
const bodyFont500 = new FontObserver("Helvetica Neue", { weight: 500 });
const bodyFont700 = new FontObserver("Helvetica Neue", { weight: 700 });
const bodyFont900 = new FontObserver("Helvetica Neue", { weight: 800 });

Promise.all([bodyFont400.load(), bodyFont500.load(), bodyFont700.load(), bodyFont900.load()]).finally(() => render());

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
