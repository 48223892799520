import AlarmSignPng from "@/assets/alarm-sign.png";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { groupAffiliationErrorsByErrorType } from "@/entities/AffiliationError/helpers/groupAffiliationErrorsByErrorType.js";
import { useAffiliationErrors } from "@/entities/AffiliationError/hooks/useAffiliationErrors/index.js";
import { AffiliationErrorTypeEnum } from "@waapi/palpatine-client";
import { Button, Flex, Heading, HeadingSize, HeadingWeight, Image, Text, TextSize, TextWeight } from "@waapi/waap-ui";
import { AffiliationErrorsType } from "./components/AffiliationErrorsType/index.js";
import { StyledModal } from "./styles.js";

export const AffiliationErrors = (): JSX.Element => {
    const { t, translations } = useTranslation();
    const { loading, affiliationErrors, setAffiliationErrorProcessed } = useAffiliationErrors();

    const confirmCloseModal = async () => {
        const affiliationErrorsIds = affiliationErrors.map((affiliationError) => affiliationError._id.toString());
        await setAffiliationErrorProcessed(affiliationErrorsIds);
    };

    const affiliationErrorsGrouped = affiliationErrors ? groupAffiliationErrorsByErrorType(affiliationErrors) : {};

    return (
        <StyledModal
            isOpen={affiliationErrors?.length > 0}
            closeOnEscape={false}
            closeOnOverlayClick={false}
            onClose={confirmCloseModal}
            fluid={false}
        >
            <Flex gap="28" direction="column" justify="center">
                <Flex gap="16" direction="row" justify="center">
                    <Image src={AlarmSignPng} alt="Alarm" dimensions={{ height: "4.25rem" }} />
                    <Flex gap="4" direction="column" justify="center">
                        <Heading size={HeadingSize.SMALL} weight={HeadingWeight.BOLD}>
                            {t(translations.affiliation_error.heading.title())}
                        </Heading>
                        <Text size={TextSize.MEDIUM} weight={TextWeight.NORMAL}>
                            {t(translations.affiliation_error.heading.subtitle())}
                        </Text>
                    </Flex>
                </Flex>
                <Flex direction="column" gap="12">
                    <Text size={TextSize.MEDIUM} weight={TextWeight.NORMAL}>
                        {t(translations.affiliation_error.content.text())}
                    </Text>
                    {Object.keys(affiliationErrorsGrouped).map((errorType) => (
                        <AffiliationErrorsType
                            key={errorType}
                            affiliationErrors={affiliationErrorsGrouped[errorType]}
                            errorType={errorType as AffiliationErrorTypeEnum}
                        />
                    ))}
                </Flex>
                <Flex direction="row" justify="end">
                    <Button onClick={confirmCloseModal} loading={loading} disabled={loading}>
                        {t(translations.affiliation_error.cta.confirm())}
                    </Button>
                </Flex>
            </Flex>
        </StyledModal>
    );
};
