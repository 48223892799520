import useRequest from "@/customHooks/useRequest.js";
import { getRootUserId, getUser, isCurrentlyImpersonating } from "@/entities/User/hooks/useUserState/selectors.js";
import { actions } from "@/entities/User/hooks/useUserState/slice.js";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useAffiliatesSummaryFromLastLogin = () => {
    const user = useSelector(getUser);
    const rootUserId = useSelector(getRootUserId);
    const isImpersonating = useSelector(isCurrentlyImpersonating);

    const dispatch = useDispatch();
    const affiliatesSummaryFromLastLogin = useRequest<"affiliatesSummaryFromLastLogin">("affiliatesSummaryFromLastLogin");

    const getAffiliatesSummaryFromLastLogin = async () => {
        const affiliatesSummaryFromLastLoginResponse = await affiliatesSummaryFromLastLogin({ query: {} });
        if (affiliatesSummaryFromLastLoginResponse.hasFailed) {
            return;
        }

        const { countPaying, count, newAffiliatePayments, newAffiliatesCanceled } = affiliatesSummaryFromLastLoginResponse.data;
        dispatch(
            actions.setAffiliatesSummaryFromLastLogin({
                countAffiliatesFromLastLogin: count,
                countPayingAffiliatesFromLastLogin: countPaying,
                newAffiliatesCanceledFromLastLogin: newAffiliatesCanceled.map((affiliate) => affiliate._id.toString()),
                newAffiliatePaymentsFromLastLogin: newAffiliatePayments.map((affiliatePayment) => affiliatePayment._id.toString()),
            }),
        );
    };

    useEffect(() => {
        if (rootUserId && !isImpersonating && user?.countAffiliatesFromLastLogin === undefined) {
            getAffiliatesSummaryFromLastLogin();
        }
    }, [user?.countAffiliatesFromLastLogin, rootUserId, isImpersonating]);
};
