import sendMessage from "@/utils/chromeMessages.js";

export function loggedToLinkedin(): Promise<boolean> {
    return sendMessage<boolean>({
        action: "GLOBAL/LOGGED_TO_LINKEDIN",
    })
        .then((isLogged) => {
            return isLogged;
        })
        .catch(() => {
            return false;
        });
}
