import React from "react";
import { WithApiClientsProvider } from "./providers/index.js";

type Props = {
    children: React.ReactNode;
};

export const ApiClientsLoader = ({ children }: Props): JSX.Element => {
    return <WithApiClientsProvider>{children}</WithApiClientsProvider>;
};
