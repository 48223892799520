import { IconKey, Spinner, Tooltip } from "@waapi/waap-ui";
import React, { useMemo } from "react";
import type { Properties as CSSProperties } from "csstype";
import crownSvg from "../../../assets/crown.svg";
import { Fade } from "../Transition/Fade/index.js";
import {
    StyledAction,
    StyledActionsContainer,
    StyledActionsSectionTitle,
    StyledAvatar,
    StyledAvatarContainer,
    StyledDropdownActions,
    StyledIcon,
    StyledSeparator,
    StyledTextAction,
} from "./style.js";

export type DropdownActionsProps = {
    actions: Record<
        string,
        {
            avatar?: string;
            icon?: IconKey | "loader";
            iconColor?: string;
            label: string;
            isBold?: boolean;
            isCrown?: boolean;
            disabled?: { tooltip: string };
            fn: React.MouseEventHandler<HTMLSpanElement>;
        }
    >;
    dividerPosition?: "top" | "bottom" | "middle";
    loading?: boolean | { loading: true; loadingKey: string } | { loading: false };
    noDivider?: boolean;
    sectionTitle?: string;
    maxHeight?: CSSProperties["maxHeight"];
    maxWidth?: CSSProperties["maxWidth"];
    listPrefixSlot?: React.ReactNode;
    animated?: boolean;
    className?: string;
};

const getPretext = ({
    action,
    loading,
    keyAction,
}: {
    action: DropdownActionsProps["actions"][number];
    loading: DropdownActionsProps["loading"];
    keyAction: string;
}) => {
    if (action.isCrown) {
        return <img src={crownSvg} alt="crown" />;
    }
    if (action.avatar) {
        return (
            <StyledAvatarContainer
                showSpinner={typeof loading === "object" && loading.loading === true && keyAction === loading.loadingKey ? loading.loading : false}
            >
                <StyledAvatar size="small" src={action.avatar} />
            </StyledAvatarContainer>
        );
    }
    if (action.icon) {
        if (action.icon === "loader" || (typeof loading === "object" && loading.loading && loading.loadingKey === keyAction)) {
            return <Spinner />;
        }
        return <StyledIcon icon={action.icon} iconColor={action.iconColor} />;
    }
    return null;
};

export const DropdownActions = (props: DropdownActionsProps): JSX.Element => {
    const {
        actions,
        loading,
        noDivider,
        sectionTitle,
        maxHeight,
        maxWidth,
        dividerPosition = "middle",
        listPrefixSlot,
        animated = false,
        className,
    } = props;

    const disabledCount = Object.values(actions).filter(({ disabled }) => disabled);

    const { actionsCount, actionsElements } = useMemo(() => {
        const count = Object.keys(actions).length;

        const getComponent = ({
            keyAction,
            action,
            index,
        }: {
            keyAction: string;
            action: DropdownActionsProps["actions"][number];
            index: number;
        }) => {
            const isDisabled = (typeof loading === "object" ? loading.loading === true : !!loading) || !!action.disabled;
            const component = (
                <StyledAction onClick={(e) => !isDisabled && action.fn(e)} disabled={isDisabled} tabIndex={-1} role="menuitem">
                    {getPretext({
                        keyAction,
                        loading,
                        action,
                    })}
                    <StyledTextAction maxWidth={maxWidth} isBold={!!action.isBold}>
                        {action.label}
                    </StyledTextAction>
                </StyledAction>
            );

            return (
                <>
                    {action.disabled ? <Tooltip content={action.disabled.tooltip}>{component}</Tooltip> : component}
                    {(!!count || sectionTitle || listPrefixSlot) && dividerPosition === "middle" && !noDivider && index !== count - 1 && (
                        <StyledSeparator />
                    )}
                </>
            );
        };

        return {
            actionsCount: count,
            actionsElements: Object.entries(actions).map(
                ([keyAction, action], index) =>
                    action &&
                    (animated ? (
                        <Fade outerWrapperStyles={{ minHeight: "auto", maxWidth: "auto", display: "block" }}>
                            {getComponent({ keyAction, action, index })}
                        </Fade>
                    ) : (
                        <React.Fragment key={keyAction}>{getComponent({ keyAction, action, index })}</React.Fragment>
                    )),
            ),
        };
    }, [disabledCount, actions.length, animated, maxWidth, dividerPosition, listPrefixSlot, loading, noDivider, sectionTitle]);

    return (
        <StyledDropdownActions className={className} maxHeight={maxHeight} maxWidth={maxWidth}>
            {(!!actionsCount || sectionTitle || listPrefixSlot) && dividerPosition === "top" && !noDivider && <StyledSeparator />}
            {sectionTitle && <StyledActionsSectionTitle>{sectionTitle}</StyledActionsSectionTitle>}
            {listPrefixSlot}
            <StyledActionsContainer maxHeight={maxHeight}>{actionsElements}</StyledActionsContainer>
            {(!!actionsCount || sectionTitle || listPrefixSlot) && dividerPosition === "bottom" && !noDivider && <StyledSeparator />}
        </StyledDropdownActions>
    );
};
