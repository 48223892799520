import { SpinnerText } from "@/app/components/SpinnerText/index.js";
import useRequest from "@/customHooks/useRequest.js";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { useUserState } from "@/entities/User/hooks/useUserState/index.js";
import { StyledDivLoading } from "@/styles/globalStyles.js";
import { useInitTranslation } from "@waapi/front-utils";
import dayjs from "dayjs";
import { useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import { DAYJS_LOCALE_MAP, TRANSLATIONS_FRAGMENT, TRANSLATIONS_LOCAL_STORAGE_PREFIX } from "./constants.js";

const WithLanguageSideEffects = ({ children }: { children: React.ReactNode }) => {
    const { currentLanguage } = useTranslation();
    const { setLanguage, userId, isCurrentlyImpersonating } = useUserState({ loadUser: false });
    const setAmbassadorLanguage = useRequest<"setAmbassadorLanguage">("setAmbassadorLanguage");

    useEffect(() => {
        dayjs.locale(DAYJS_LOCALE_MAP[currentLanguage]);
        setLanguage(currentLanguage);
    }, [currentLanguage]);

    useEffect(() => {
        if (!isCurrentlyImpersonating && userId) {
            setAmbassadorLanguage({ body: { language: currentLanguage } });
        }
    }, [isCurrentlyImpersonating, userId, currentLanguage]);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
};

const WithI18nProvider = ({ children }: { children: React.ReactNode }) => {
    const { i18n } = useTranslation();

    return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

export const WithTranslationsProvider = ({ children }: { children: React.ReactNode }) => {
    const { loading: loadingTranslation } = useInitTranslation({
        fragment: TRANSLATIONS_FRAGMENT,
        localStoragePrefix: TRANSLATIONS_LOCAL_STORAGE_PREFIX,
        pathSuffix: "/ambassadors",
    });

    if (loadingTranslation)
        return (
            <StyledDivLoading>
                <SpinnerText animationState="stopped" />
            </StyledDivLoading>
        );

    return (
        <WithI18nProvider>
            <WithLanguageSideEffects>{children}</WithLanguageSideEffects>
        </WithI18nProvider>
    );
};
