import sendMessage from "@/utils/chromeMessages.js";

export function importAffiliates(params: {
    user: string;
    prospectListId: string;
    prospectListName: string;
    linkedinIds: Array<string>;
    allowDuplicate: boolean;
    moveDuplicatesToOtherList: boolean;
}): Promise<void> {
    return sendMessage<void>({
        action: "AMBASSADOR/IMPORT_AFFILIATES",
        params,
    });
}
