import useRequest from "@/customHooks/useRequest.js";
import { UseAffiliationErrorManagerReturnTypes } from "@/entities/AffiliationError/hooks/useAffiliationErrorManager/types.js";

export const useAffiliationErrorManager = (): UseAffiliationErrorManagerReturnTypes => {
    const getAffiliationErrorsRequest = useRequest<"getAffiliationErrors">("getAffiliationErrors");
    const setAffiliationErrorsProcessedRequest = useRequest<"setAffiliationErrorsProcessed">("setAffiliationErrorsProcessed");

    const getAffiliationErrors: UseAffiliationErrorManagerReturnTypes["getAffiliationErrors"] = async () => {
        const getAffiliationErrorsResponse = await getAffiliationErrorsRequest();

        if (getAffiliationErrorsResponse.hasFailed) {
            return [];
        }

        return getAffiliationErrorsResponse.data.affiliationErrors;
    };

    const setAffiliationErrorsProcessed: UseAffiliationErrorManagerReturnTypes["setAffiliationErrorsProcessed"] = async (params) => {
        const setAffiliationErrorsProcessedResponse = await setAffiliationErrorsProcessedRequest({
            body: params,
        });

        return !setAffiliationErrorsProcessedResponse.hasFailed;
    };

    return {
        getAffiliationErrors,
        setAffiliationErrorsProcessed,
    };
};
