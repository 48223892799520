import { useTranslation } from "@/customHooks/useTranslation/index.js";
import stopCurrentExport from "@/services/ChromeMessaging/CurrentExport/stopCurrentExport.js";
import { TextWeight, useToasts } from "@waapi/waap-ui";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer } from "redux-injectors";
import { useTheme } from "styled-components";
import { ImportViewDisplay } from "./components/ImportView/index.js";
import { ImportView } from "./constants.js";
import { handleCSVImportMessage } from "./handlers/index.js";
import { selectUseImportState, selectUseImportStateAllLists } from "./selectors.js";
import { actions, reducer, sliceKey } from "./slice.js";
import { ToastStatus } from "./types.js";
import { CHROME_EXTENSION_ID } from "@/utils/constants.js";
import { useWaalaxyExtension } from "@/customHooks/useWaalaxyExtension/index.js";

export const useImportState = () => {
    useInjectReducer({ key: sliceKey, reducer });
    const dispatch = useDispatch();
    const theme = useTheme();
    const { t, translations } = useTranslation();
    const { extensionIsInstalled } = useWaalaxyExtension();

    const [currentImportId, setCurrentImportId] = useState<string | undefined>(undefined);
    const { addToast, updateToast, removeToast, toastStack } = useToasts();
    const allImportStates = useSelector(selectUseImportStateAllLists);

    const toastStatus = useRef<ToastStatus>("notCreated");

    useLayoutEffect(() => {
        if (!currentImportId || allImportStates[currentImportId]) return;

        dispatch(actions.init(currentImportId));
    }, [currentImportId]);

    const {
        listData: { prospectList, loading: loadingProspectList },
        toastId,
        view,
        importProgression,
        maxProspectCount,
        importId,
        isHidden,
    } = useSelector(selectUseImportState(currentImportId));

    useEffect(() => {
        if (importId && !currentImportId) {
            setCurrentImportId(importId);
        }
    }, [importId]);

    const handleMessage = useCallback(
        (message: unknown) => {
            const result = handleCSVImportMessage({
                message,
                view,
                isHidden,
            });
            if (result) {
                const { importId: newImportId, stateUpdate } = result;
                if (currentImportId !== newImportId) {
                    setCurrentImportId(newImportId);
                }
                dispatch(
                    actions.setUseImportState({
                        importId: newImportId,
                        ...stateUpdate,
                    }),
                );
            }
        },
        [view, isHidden, currentImportId],
    );

    const handleStopImport = useCallback(async () => {
        if (!currentImportId) return;

        await stopCurrentExport();
    }, [currentImportId]);

    useEffect(() => {
        let port: ReturnType<(typeof global)["chrome"]["runtime"]["connect"]> | undefined;

        if (extensionIsInstalled) {
            port = window.chrome.runtime.connect(CHROME_EXTENSION_ID, {
                name: "IMPORT_CSV_PROSPECTS",
            });
            port.onMessage.addListener(handleMessage);
        }

        return () => {
            if (port) {
                port.onMessage.removeListener(handleMessage);
                port.disconnect();
            }
        };
    }, [handleMessage, extensionIsInstalled]);

    useEffect(() => {
        if (!currentImportId || !view || isHidden) {
            if (toastId && toastStack.find((toast) => toast.id === toastId)) {
                removeToast(toastId);
                toastStatus.current = "notCreated";
            }

            return;
        }

        if (toastStatus.current === "created" && toastId && toastStack.find((toast) => toast.id === toastId)) {
            updateToast(toastId, {
                content: (
                    <ImportViewDisplay
                        maxProspectCount={maxProspectCount}
                        progression={importProgression}
                        listData={{ prospectList, loading: loadingProspectList }}
                        view={view}
                        importId={currentImportId}
                        onStopImport={handleStopImport}
                    />
                ),
                onDismiss: () => {
                    if (currentImportId) {
                        dispatch(actions.setIsHidden({ key: currentImportId, isHidden: true }));
                        toastStatus.current = "notCreated";
                    }
                },
                ...(view === ImportView.IMPORT_STOPPED
                    ? { title: t(translations.toast.import_state.done()), appearance: "success", size: "regular" }
                    : {}),
            });
        } else if (toastStatus.current === "notCreated") {
            toastStatus.current = "beingCreated";
            addToast(
                <ImportViewDisplay
                    maxProspectCount={maxProspectCount}
                    progression={importProgression}
                    listData={{ prospectList, loading: loadingProspectList }}
                    view={view}
                    importId={currentImportId}
                    onStopImport={handleStopImport}
                />,
                {
                    title: t(translations.import.progress_title(), {
                        prospectList: prospectList?.name,
                    }),
                    appearance: "info",
                    autoDismiss: false,
                    id: toastId,
                    titleColor: theme.grayscale[100],
                    contentVerticalAlignment: "stretch",
                    titleWeight: TextWeight.BOLD,
                    size: "large",
                    onDismiss: () => {
                        if (currentImportId) {
                            dispatch(actions.setIsHidden({ key: currentImportId, isHidden: true }));
                            toastStatus.current = "notCreated";
                        }
                    },
                },
                () => {
                    toastStatus.current = "created";
                },
            );
        }
    }, [currentImportId, isHidden, view, toastId, JSON.stringify(importProgression), maxProspectCount]);
};
