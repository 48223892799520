import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@/types/RootState.js";
import { ImportView } from "./constants.js";
import { initialKeyState, initialState } from "./slice.js";

const selectDomain = (state: RootState) => state.useImportStateState || initialState;

export const selectUseImportState = (listId?: string) =>
    createSelector([selectDomain], (state) => (listId ? state[listId] || initialKeyState : initialKeyState));

export const selectUseImportStateAllLists = createSelector([selectDomain], (state) => state || initialKeyState);

export const hasExportOngoing = createSelector([selectDomain], (state) =>
    Object.values(state).some((keyState) => keyState.view === ImportView.IMPORT_STARTED || keyState.view === ImportView.IMPORT_PROGRESSION),
);

export const isImportStarted = createSelector([selectDomain], (state) => {
    const key = Object.keys(state).pop();
    return key && state[key].view === ImportView.IMPORT_PROGRESSION;
});

export const currentImportId = createSelector([selectDomain], (state) => {
    const key = Object.keys(state).pop();
    return key && state[key].importId;
});

export const currentListId = createSelector([selectDomain], (state) => {
    const key = Object.keys(state).pop();
    return key;
});
