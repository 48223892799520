import { AxiosClient } from "@waapi/palpatine-client";
import { RawAxiosRequestHeaders } from "axios";

export const palpatineMethodsMap = (palpatineClient: AxiosClient) => ({
    signup: palpatineClient.public.ambassadors.signUp,
    login: palpatineClient.public.ambassadors.login,
    getProfile: palpatineClient.private.ambassadors.profile.getProfile,
    getOnboardingUrl: palpatineClient.private.accounts.getOnboardingUrl,
    getUpdateUrl: palpatineClient.private.accounts.getUpdateUrl,
    updatePassword: palpatineClient.private.ambassadors.profile.updatePassword,
    getPayments: palpatineClient.private.ambassadors.payment.getPayments,
    getAffiliatesPayments: palpatineClient.private.affiliates.getAffiliatePayments,
    getNextPayment: palpatineClient.private.accounts.getNextBalance,
    getPreviousPayment: palpatineClient.private.ambassadors.payment.getPreviousPaymentAmount,
    getAffiliates: palpatineClient.private.affiliates.getAffiliates,
    getStripeSettings: palpatineClient.private.accounts.getStripeSettings,
    getLinks: palpatineClient.private.ambassadors.links.getLinks,
    createLink: palpatineClient.private.ambassadors.links.createLink,
    deleteLink: palpatineClient.private.ambassadors.links.deleteLink,
    getSummary: palpatineClient.private.ambassadors.links.getSummary,
    updateLink: palpatineClient.private.ambassadors.links.updateLink,
    linkAccount: palpatineClient.private.waalaxy.linkAccount,
    forgetPassword: palpatineClient.public.ambassadors.forgetPassword,
    getAccountRequirements: palpatineClient.private.accounts.getAccountRequirements,
    createAffiliation: palpatineClient.private.ambassadors.affiliation.createAffiliation,
    getCountryList: palpatineClient.public.stripe.getCountryList,
    getMyIntercomIdentifiers: palpatineClient.private.ambassadors.profile.getIntercomIdentifiers,
    getWallet: palpatineClient.private.ambassadors.wallets.getWallet,
    getAccountLoginUrl: palpatineClient.private.accounts.getAccountLoginUrl,
    acceptContract: palpatineClient.private.ambassadors.contracts.acceptContract,
    affiliatesSummaryFromLastLogin: palpatineClient.private.affiliates.getAffiliatesSummaryFromLastLogin,
    setAmbassadorLanguage: palpatineClient.private.ambassadors.language.setLanguage,
    getAmbassadorLanguage: palpatineClient.private.ambassadors.language.getLanguage,
    sendInviteToUser: palpatineClient.private.ambassadors.sendInviteToUser,
    exportAffiliates: palpatineClient.private.affiliates.exportAffiliatesData,
    getAffiliationErrors: palpatineClient.private.affiliationErrors.getAffiliationErrors,
    setAffiliationErrorsProcessed: palpatineClient.private.affiliationErrors.setAffiliationErrorsProcessed,
    getAffiliatesLinkedinIds: palpatineClient.private.affiliates.getAffiliatesLinkedinIds,
    startStripeOnboarding: palpatineClient.private.ambassadors.stripeAccount.startStripeOnboarding,
});

export const palpatineLifelineMethodsMap = (palpatineClient: AxiosClient) => ({
    impersonateAmbassador: (headers: RawAxiosRequestHeaders) => palpatineClient.setHeaders(headers).public.ambassadors.impersonate,
});
