import { ValidCSVImportMessage } from "../types.js";

export const isValidCSVImportMessage = (message: unknown): message is ValidCSVImportMessage =>
    typeof message === "object" &&
    message !== null &&
    typeof (message as ValidCSVImportMessage).type === "string" &&
    [
        "CURRENT_STATUS",
        "IMPORT_CSV_STARTED",
        "IMPORT_CSV_PROGRESSION",
        "IMPORT_CSV_STOPPED",
        "IMPORT_CSV_METADATA_UPDATED",
        "EXPORT_LIMIT_REACHED",
    ].includes((message as ValidCSVImportMessage).type);
