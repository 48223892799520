import { IAmbassador, GetIntercomIdentifiersResponse200 as UserIntercomIdentifiers } from "@waapi/palpatine-client";

export declare type UseUserStateReturnType = {
    loading: boolean;
    isCurrentlyImpersonating: boolean;
    language?: string;
    userId?: string;
    setLanguage: (language: string) => void;
};

export declare interface MiniProfile {
    firstName: string;
    lastName: string;
    occupation: string;
    publicIdentifier: string;
    memberId: string;
    profilePicture: string;
}

export enum SecondaryAccountType {
    IMPERSONATION = "impersonation",
    STAFF_CRM_ACCESS = "staff_crm_access",
}

export declare interface MeData extends MiniProfile {
    linkedinId: number;
    isPremiumSubscriber: boolean;
}

export declare interface PhoneNumber {
    type: string;
    number: string;
}

export declare interface CompanyData {
    company?: { url?: string; name?: string };
}

export declare interface ContactData {
    birthday: { month: number; day: number };
    address: string;
    connectedAt: number;
    email: string;
    phoneNumbers: PhoneNumber[];
}

export declare interface LinkedinAuthUser extends MeData, MiniProfile, ContactData, CompanyData {}

export interface LinkedinAccountState {
    readonly profile?: LinkedinAuthUser;
}

export type IEnrichedAmbassador = {
    readonly countAffiliatesFromLastLogin?: number;
    readonly countPayingAffiliatesFromLastLogin?: number;
    readonly newAffiliatePaymentsFromLastLogin?: string[];
    readonly newAffiliatesCanceledFromLastLogin?: string[];
} & IAmbassador;

export interface RootAccountState {
    readonly apiToken: string;
    readonly user: IEnrichedAmbassador;
    readonly hasRequirements: boolean;
}

export interface AuthState {
    readonly linkedinAccount?: LinkedinAccountState;
    readonly rootAccount?: RootAccountState;
    readonly secondaryAccount?: RootAccountState & {
        readonly type?: SecondaryAccountType;
        readonly profile?: LinkedinAuthUser;
    };
}

export interface GlobalState {
    isLoginToAnOtherAccount: boolean;
    auth: AuthState;
    isLoadingUser: boolean;
    reloadExtension: boolean;
    intercomIdentifiers?: UserIntercomIdentifiers;
}

/* --- STATE --- */
export type UseUserStateState = GlobalState;

export type ContainerState = GlobalState;
