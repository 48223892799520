import React from "react";
import { AppCrashed } from "./components/AppCrashed/index.js";

export class ErrorBoundary extends React.Component<{ children: JSX.Element }, { hasError: boolean }> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.warn(error, errorInfo);
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;
        if (hasError) {
            return <AppCrashed />;
        }

        return children;
    }
}
