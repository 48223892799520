import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContainerState } from "./types.js";

export const initialState: ContainerState = {
    extensionIsInstalled: false,
};

const useWaalaxyExtensionSlices = createSlice({
    name: "useWaalaxyExtension",
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
        setExtensionIsInstalled(state, action: PayloadAction<boolean>) {
            state.extensionIsInstalled = action.payload;
        },
        setWaalaxyExtensionState(state, action: PayloadAction<ContainerState["extensionState"]>) {
            state.extensionState = action.payload;
        },
    },
});

export const { actions, reducer, name: sliceKey } = useWaalaxyExtensionSlices;
