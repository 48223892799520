import { FormikProps } from "formik";

export type AuthFormValues = {
    email: string;
    password: string;
    country: string;
    agree: boolean;
};

export enum AuthFormComponent {
    PASSWORD = "password",
    EMAIL = "email",
    COUNTRY = "country",
}

export type Props<Components extends Array<AuthFormComponent>> = {
    state: {
        form: FormikProps<any>;
    };
    components: Components;
};

export type ReturnType<Components extends Array<AuthFormComponent>> = {
    components: Record<Components[number], JSX.Element>;
};
