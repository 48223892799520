import { useAuthForm } from "@/app/components/AuthForm/index.js";
import { AuthFormComponent } from "@/app/components/AuthForm/types.js";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { useUserManager } from "@/entities/User/hooks/useUserManager/index.js";
import { Button, ITheme, Text, TextSize, TextWeight } from "@waapi/waap-ui";
import { Formik } from "formik";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "styled-components";
import { Footer, Form, InputsWrapper, LinkContent } from "./style.js";
import { LoginValues } from "./types.js";
import { loginValidationSchema } from "./validation.js";

export const LoginForm = (): JSX.Element => {
    const { t, translations } = useTranslation();
    const theme = useTheme() as ITheme;
    const { login } = useUserManager();

    const initialValues = useMemo<LoginValues>(() => ({ email: "", password: "" }), []);

    const onLogin = async (values: LoginValues) => login({ body: values });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={loginValidationSchema}
            onSubmit={onLogin}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {(formState) => {
                const { components } = useAuthForm({
                    state: { form: formState },
                    components: [AuthFormComponent.EMAIL, AuthFormComponent.PASSWORD],
                });

                return (
                    <Form>
                        <InputsWrapper>
                            {components.email}
                            {components.password}
                        </InputsWrapper>

                        <Footer>
                            <Text color={theme.colors.primary[100]} size={TextSize["X-SMALL"]} weight={TextWeight.SEMIBOLD}>
                                <Link to="/forgot-password">
                                    <LinkContent>{t(translations.pages.login.forgot_password())}</LinkContent>
                                </Link>
                            </Text>

                            <Button type="submit" loading={formState.isSubmitting}>
                                {t(translations.pages.login.form.submit.text())}
                            </Button>
                        </Footer>
                    </Form>
                );
            }}
        </Formik>
    );
};
