import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { Button, Icon, Text, TextSize, TextWeight } from "@waapi/waap-ui";
import { Container } from "./style.js";
import { LargeAskWaalaxyAccountProps } from "./types.js";

export const LargeAskWaalaxyAccount = ({ onClick }: LargeAskWaalaxyAccountProps): JSX.Element => {
    const { t, translations } = useTranslation();

    return (
        <Container>
            <Icon icon="circleArrow" size="large" />
            <Text align="center" size={TextSize["X-SMALL"]} weight={TextWeight.BOLD}>
                {t(translations.waalaxy.link_account.title())}
            </Text>
            <Button width="100%" onClick={onClick}>
                {t(translations.waalaxy.link_account.button())}
            </Button>
        </Container>
    );
};
