import { AuthPage } from "@/app/components/AuthPage/index.js";
import { useMixpanelTrack } from "@/customHooks/useMixpanel/index.js";
import { AnalyticsEventName } from "@/customHooks/useMixpanel/types.js";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { useEffect } from "react";
import { SignupForm } from "./SignupForm/index.js";

export const Signup = (): JSX.Element => {
    const { trackEvent } = useMixpanelTrack();
    const { t, translations } = useTranslation();

    useEffect(() => {
        trackEvent({
            eventName: AnalyticsEventName.SIGNUP_PAGE_SHOWN,
            properties: {},
        });
    }, []);

    return (
        <AuthPage
            title={translations.pages.signup.title()}
            subtitle={{ text: translations.pages.signup.subtitle(), link: { children: t(translations.pages.signup.subtitle_link()), to: "/login" } }}
            form={<SignupForm />}
        />
    );
};
