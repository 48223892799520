/* eslint-disable import/no-import-module-exports */
import { configureStore } from "@reduxjs/toolkit";
import { createInjectorsEnhancer } from "redux-injectors";
import createSagaMiddleware from "redux-saga";
import ApiEventManager from "@/utils/ApiEventManager.js";
import { createReducer } from "./reducers.js";

const SideEffectMiddleware = () => (next) => (action) => {
    ApiEventManager.emit(action.type, action.payload);
    return next(action);
};

export function configureAppStore() {
    // @ts-expect-error bad typing in package
    const sagaMiddleware = createSagaMiddleware({});
    const middlewares = [SideEffectMiddleware, sagaMiddleware];

    const enhancers = [
        createInjectorsEnhancer({
            createReducer,
            runSaga: sagaMiddleware.run,
        }),
    ];

    const store = configureStore({
        reducer: createReducer(),
        middleware: (gDM) => gDM().concat(middlewares),
        devTools: process.env.NODE_ENV !== "production",
        enhancers,
    });

    return store;
}
