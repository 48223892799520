import { createSlice } from "@reduxjs/toolkit";
import { UseMixpanelState } from "./types.js";

export const initialState: UseMixpanelState = {
    authenticated: false,
};

const useMixpanelSlice = createSlice({
    name: "useMixpanel",
    initialState,
    reducers: {
        init() {
            return initialState;
        },
        removeState() {
            return initialState;
        },
        setAuthenticated(state) {
            state.authenticated = true;
        },
    },
});

export const { actions, reducer, name: sliceKey } = useMixpanelSlice;
