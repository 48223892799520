import useRequest from "@/customHooks/useRequest.js";
import { useEffect, useState } from "react";
import { doesItHaveRequirements } from "./helpers/doesItHaveRequirements.js";
import { UseAccountRequirementsReturnType } from "./types.js";

export const useAccountRequirements = (): UseAccountRequirementsReturnType => {
    const [loading, setLoading] = useState(true);
    const [hasRequirements, setHasRequirements] = useState(false);
    const getAccountRequirements = useRequest<"getAccountRequirements">("getAccountRequirements");

    const fetchAccountRequirements = async () => {
        const res = await getAccountRequirements();

        if (res.hasFailed) {
            setLoading(false);
            return;
        }

        Object.values(res.data.requirements).reduce((acc, curr) => {
            if (!curr) return acc;
            if (Array.isArray(curr) && curr.length) return true;
            return acc;
        }, false);

        setHasRequirements(doesItHaveRequirements(res.data.requirements));
        setLoading(false);
    };

    useEffect(() => {
        fetchAccountRequirements();
    }, []);

    return { loading, hasRequirements };
};
