import useRequest from "@/customHooks/useRequest.js";
import { GetLinksResponse200 } from "@waapi/palpatine-client";
import { useEffect, useState } from "react";
import { UseLinksProps } from "./type.js";

export const useLinks = ({ filters }: UseLinksProps) => {
    const [loading, setLoading] = useState(true);
    const [links, setLinks] = useState<GetLinksResponse200["links"]>([]);

    const getLinks = useRequest<"getLinks">("getLinks");
    const updateLinkRequest = useRequest<"updateLink">("updateLink");

    const fetchLinks = async (...params: Parameters<typeof getLinks>) => {
        const res = await getLinks(...params);

        if (res.hasFailed) return;
        const sortedLinks = res.data.links.sort((a, b) => (b.tag === "landing" ? 1 : 0) - (a.tag === "landing" ? 1 : 0));
        setLinks(sortedLinks);
    };

    const updateLink = async (params: { linkId: string; name: string }) => {
        const { linkId, name } = params;
        const res = await updateLinkRequest({
            body: {
                name,
            },
            pathParams: {
                linkId,
            },
        });

        if (res.hasFailed) return;

        const newLinks = links.reduce<GetLinksResponse200["links"]>((acc, link) => {
            if (link._id.toString() === linkId) {
                return [...acc, { ...link, name }];
            }

            return [...acc, link];
        }, []);

        setLinks(newLinks);
    };

    const initLinks = async () => {
        setLoading(true);
        setLinks([]);
        await fetchLinks({ query: filters });
        setLoading(false);
    };

    useEffect(() => {
        initLinks();
    }, [JSON.stringify(filters)]);

    return { loading, links, refreshLinksList: () => fetchLinks({ query: filters }), updateLink };
};
