import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { Button, ITheme, Text, TextWeight } from "@waapi/waap-ui";
import { Link, LinkProps } from "react-router-dom";
import { useTheme } from "styled-components";
import { Container } from "./style.js";

export const FooterAuthPage = ({
    subtitle,
}: {
    subtitle?: {
        text: string;
        link?: LinkProps;
    };
}): JSX.Element => {
    const { t, translations } = useTranslation();
    const theme = useTheme() as ITheme;

    return (
        <Container>
            <Text color={theme.grayscale.white} weight={TextWeight.SEMIBOLD}>
                {t(translations.pages.auth.footer.text())}
            </Text>
            {subtitle && subtitle.link && (
                <Link {...subtitle.link}>
                    <Button color="primary" variant="outline">
                        {subtitle.link.children}
                    </Button>
                </Link>
            )}
        </Container>
    );
};
