import { EventEmitter } from "events";

class ApiEventManager {
    public eventEmitter: EventEmitter;

    constructor() {
        this.eventEmitter = new EventEmitter();
    }

    public on(eventName, listener): void {
        this.eventEmitter.on(eventName, listener);
    }

    public removeEventListener(eventName, listener): void {
        this.eventEmitter.removeListener(eventName, listener);
    }

    public emit(event, payload, error = false): void {
        this.eventEmitter.emit(event, payload, error);
    }

    public getEventEmitter(): EventEmitter {
        return this.eventEmitter;
    }
}

export default new ApiEventManager();
