import { Icon, Text } from "@waapi/waap-ui";
import styled from "styled-components";

export const UserName = styled(Text)`
    max-width: 25ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const StyledHeaderInner = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.75rem;
    background-color: ${(props) => props.theme.grayscale.white};
    clip-path: url(#header-mask);

    .icon-refresh-extension {
        cursor: pointer;
        color: ${(props) => props.theme.colors.primary[100]};
    }

    padding: 0.75rem 4rem;
    margin-left: 0.125rem;
    margin-right: 0.125rem;
    margin-bottom: 0.125rem;
`;

export const StyledHeader = styled.div`
    position: fixed;
    top: 0;
    max-width: 100%;
    left: 50%;
    background-color: ${(props) => props.theme.grayscale.white};
    transform: translateX(-50%);
    clip-path: url(#header-mask);
    z-index: ${({ theme: { elevationLevels } }) => elevationLevels.one};
`;

export const StyledDropdownDiv = styled.div`
    cursor: pointer;
`;

export const HeaderDropdownIcon = styled(Icon)`
    display: block;
`;

export const StyledUserMenu = styled.div<{ isDropdownOpen?: boolean }>`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    cursor: pointer;
    min-width: 0;
    overflow: hidden;
    align-items: center;

    > * {
        flex: 0 0 auto;
    }

    ${HeaderDropdownIcon} {
        transition: transform 125ms;
        transform: ${({ isDropdownOpen }) => (isDropdownOpen ? "translateY(0.125rem) scaleX(1.03)" : "translateY(0) scaleX(1)")};
    }

    &:hover ${HeaderDropdownIcon} {
        transform: translateY(0.125rem) scaleX(1.03);
    }
`;
