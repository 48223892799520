import { AuthPage } from "@/app/components/AuthPage/index.js";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import React from "react";
import { ForgotPasswordForm } from "./ForgotPasswordForm/index.js";

export const ForgotPassword = (): JSX.Element => {
    const { t, translations } = useTranslation();

    return (
        <AuthPage
            title={translations.pages.forgotPassword.title()}
            subtitle={{
                text: translations.pages.forgotPassword.subtitle(),
                link: { children: t(translations.pages.forgotPassword.subtitle_link()), to: "/login" },
            }}
            form={<ForgotPasswordForm />}
        />
    );
};
