/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { LanguageSelect } from "../../containers/LanguageSelect/index.js";
import { StyledContainer } from "./style.js";

interface Props {
    size?: "small" | "large";
    variant?: "dark" | "light";
}

export function FloatingLanguageSelect(props: Props): JSX.Element {
    return (
        <StyledContainer>
            <LanguageSelect {...props} />
        </StyledContainer>
    );
}
