export interface UseMixpanelState {
    authenticated?: boolean;
}

export enum AnalyticsEventName {
    SIGNUP_PAGE_SHOWN = "signupPageShown",
    ENCOUNTER_AN_ERROR = "encounterAnError",
    OPENED_WAALAXY_TEMPLATES = "openedWaalaxyTemplates",
    START_STRIPE_ONBOARDING_BUTTON_CLICKED = "startStripeOnboardingButtonClicked",
}

export type TrackEventParams = {
    eventName: AnalyticsEventName;
    properties: Record<string, string | number | boolean>;
};

export type TrackErrorParams = {
    errorCode: string;
};

export type UseMixpanelReturnType = {
    trackEvent: (params: TrackEventParams) => void;
    trackError: (params: TrackErrorParams) => void;
};
