import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: ${(props) => props.theme.spacing[12]};
`;

export const TextImage = styled.img`
    height: 3.25rem;
`;

export const LogoImage = styled.img`
    height: 2.5rem;
`;
