import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContainerState } from "./types.js";
import { IAffiliationError } from "@waapi/palpatine-client";

export const initialState: ContainerState = {};

export const initialKeyState: ContainerState[keyof ContainerState] = {
    loading: false,
    affiliationErrors: [],
};

const useAffiliationErrorsSlice = createSlice({
    name: "useAffiliationErrors",
    initialState,
    reducers: {
        init(state, action: PayloadAction<string>) {
            state[action.payload] = initialKeyState;
        },
        setLoading(state, action: PayloadAction<{ key: string; loading: boolean }>) {
            const { key, loading } = action.payload;
            state[key].loading = loading;
        },
        removeState(state, action: PayloadAction<string>) {
            state[action.payload] = initialKeyState;
        },
        setAffiliationErrors(state, action: PayloadAction<{ key: string; affiliationErrors: IAffiliationError[] }>) {
            const { key, affiliationErrors } = action.payload;
            state[key].affiliationErrors = affiliationErrors;
        },
        removeProcessedAffiliationErrors(state, action: PayloadAction<{ affiliationErrorsIds: string[] }>) {
            const { affiliationErrorsIds } = action.payload;
            Object.keys(state).forEach((key) => {
                state[key].affiliationErrors = state[key].affiliationErrors.reduce<IAffiliationError[]>((acc, affiliationError) => {
                    if (affiliationErrorsIds.includes(affiliationError._id.toString())) {
                        return acc;
                    }

                    acc.push(affiliationError);
                    return acc;
                }, []);
            });
        },
    },
});

export const { actions, reducer, name: sliceKey } = useAffiliationErrorsSlice;
