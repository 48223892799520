import { UseExtensionManagerReturnType } from "@/customHooks/useExtensionManager/types.js";
import { getProspectsLists as getProspectsListsChromeMessaging } from "@/services/ChromeMessaging/ProspectList/getProspectsLists.js";
import { createProspectList as createProspectListChromeMessaging } from "@/services/ChromeMessaging/ProspectList/createProspectList.js";
import { importAffiliates as importAffiliatesChromeMessaging } from "@/services/ChromeMessaging/Ambassador/importAffiliates.js";

export const useExtensionManager = (): UseExtensionManagerReturnType => {
    const getProspectsLists = async () => {
        return getProspectsListsChromeMessaging();
    };

    const createProspectList: UseExtensionManagerReturnType["createProspectList"] = async (name) => {
        return createProspectListChromeMessaging(name);
    };

    const importAffiliates: UseExtensionManagerReturnType["importAffiliates"] = async (params) => {
        return importAffiliatesChromeMessaging(params);
    };

    return {
        getProspectsLists,
        createProspectList,
        importAffiliates,
    };
};
