import { Routes } from "react-router-dom";
import { RoutesTypes } from "../../../types/routes.types.js";
import { buildRoutes } from "../../../utils/buildRoutes.utils.js";
import { Login } from "./Login/index.js";

const loginRoutes: RoutesTypes = [
    {
        path: "/",
        element: <Login />,
    },
];

export const LoginRoutes = (): JSX.Element => {
    return <Routes>{buildRoutes(loginRoutes)}</Routes>;
};
