import axios from "axios";

export const getLanguages =
    (url: string, token: string) =>
    async (params: {
        fragment: string;
    }): Promise<{
        languages: Array<string>;
    }> => {
        const { data } = await axios.get(`${url}/shakespeare/translations/languages`, {
            params,
            headers: { Authorization: `Bearer ${token}` },
        });
        return data;
    };
