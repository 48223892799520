import { CustomTFunction, TranslationDictionary } from "@/customHooks/useTranslation/types.js";
import { LinkAccountResponseErrors } from "@waapi/palpatine-client";

export const getErrorMaps = (t: CustomTFunction, translations: TranslationDictionary) => {
    const linkWaalaxyAccountErrorCode: Record<LinkAccountResponseErrors & "default", string> = {
        ambassador_already_affiliated: t(translations.waalaxy.toasts.failed.ambassador_already_affiliate()),
        default: t(translations.waalaxy.toasts.failed.default()),
    };

    return {
        linkWaalaxyAccountErrorCode,
    };
};
