import { Button, Heading, HeadingSize, HeadingWeight, Text, TextSize } from "@waapi/waap-ui";
import { useNavigate } from "react-router-dom";
import WaalienSVG from "../../../assets/blue-flat_waalien.svg";
import { useTranslation } from "../../../customHooks/useTranslation/index.js";
import { Body, Container, Content, Logo, LogoutButtonContainer, Titles } from "./style.js";
import { OnboardingPageProps } from "./types.js";

export const OnboardingPage = ({ title, subtitles, body, maxWidth }: OnboardingPageProps): JSX.Element => {
    const { t, translations } = useTranslation();
    const navigate = useNavigate();

    return (
        <Container>
            <LogoutButtonContainer>
                <Button variant="tinted" onClick={() => navigate("/logout")}>
                    {t(translations.onboarding.logout.button())}
                </Button>
            </LogoutButtonContainer>
            <Content maxWidth={maxWidth}>
                <Logo src={WaalienSVG} />

                <Body>
                    <Titles>
                        <Heading size={HeadingSize.SMALL} weight={HeadingWeight.BOLD}>
                            {title}
                        </Heading>
                        <div>
                            {subtitles.map((subtitle) => (
                                <Text key={subtitle} size={TextSize.MEDIUM}>
                                    {subtitle}
                                </Text>
                            ))}
                        </div>
                    </Titles>

                    {body}
                </Body>
            </Content>
        </Container>
    );
};
