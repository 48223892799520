import { useAuthForm } from "@/app/components/AuthForm/index.js";
import { AuthFormComponent } from "@/app/components/AuthForm/types.js";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { useUserManager } from "@/entities/User/hooks/useUserManager/index.js";
import { Button } from "@waapi/waap-ui";
import { Formik } from "formik";
import { useMemo, useState } from "react";
import { SuccessPasswordForgotten } from "./SuccessPasswordForgotten/index.js";
import { Footer, Form, InputsWrapper } from "./style.js";
import { ForgotPasswordValues } from "./types.js";
import { forgotPasswordValidationSchema } from "./validation.js";

export const ForgotPasswordForm = (): JSX.Element => {
    const { t, translations } = useTranslation();
    const { forgetPassword } = useUserManager();

    const [hasEmailBeenSent, setHasEmailBeenSent] = useState(false);
    const initialValues = useMemo<ForgotPasswordValues>(() => ({ email: "" }), []);

    const onForgotPassword = async (values: ForgotPasswordValues) =>
        forgetPassword({ params: [{ body: values }], onEmailSent: () => setHasEmailBeenSent(true) });

    if (hasEmailBeenSent) return <SuccessPasswordForgotten />;

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={forgotPasswordValidationSchema}
            onSubmit={onForgotPassword}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {(formState) => {
                const { components } = useAuthForm({
                    state: { form: formState },
                    components: [AuthFormComponent.EMAIL],
                });

                return (
                    <Form>
                        <InputsWrapper>{components.email}</InputsWrapper>

                        <Footer>
                            <Button type="submit" loading={formState.isSubmitting}>
                                {t(translations.pages.forgotPassword.form.submit.text())}
                            </Button>
                        </Footer>
                    </Form>
                );
            }}
        </Formik>
    );
};
