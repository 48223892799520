import { isLoggedInApi } from "@/entities/User/hooks/useUserState/selectors.js";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { PublicRouteProps } from "./types.js";

export const PublicRoute = ({ children }: PublicRouteProps): JSX.Element => {
    const isLoggedIn = useSelector(isLoggedInApi);

    if (isLoggedIn) {
        return <Navigate to="/home" />;
    }

    return children;
};
