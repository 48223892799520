import styled from "styled-components";

export const Container = styled.div`
    height: 100%;
    width: 100%;
    background: ${(props) => props.theme.gradients.blueToPurple};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${(props) => props.theme.spacing[32]};
    color: ${(props) => props.theme.grayscale.white};
`;

export const Content = styled.div`
    display: flex;
    gap: ${(props) => props.theme.spacing[32]};
`;

export const Logo = styled.img`
    height: 6rem;
    width: 6rem;
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[32]};
`;

export const Titles = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[12]};
`;

export const AdvantageSelectorWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${(props) => props.theme.spacing[4]};
    flex-wrap: wrap;
`;

export const AdvantageSelector = styled.div``;
