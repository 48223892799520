import { useUserState } from "@/entities/User/hooks/useUserState/index.js";
import { StyledDivLoading } from "@/styles/globalStyles.js";
import { SpinnerText } from "./components/SpinnerText/index.js";

type Props = {
    children: JSX.Element;
};

export const UserLoader = ({ children }: Props): JSX.Element => {
    const { loading } = useUserState({});

    return loading ? (
        <StyledDivLoading>
            <SpinnerText animationState="stopped" />
        </StyledDivLoading>
    ) : (
        children
    );
};
