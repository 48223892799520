import { Routes } from "react-router-dom";
import { RoutesTypes } from "../../../types/routes.types.js";
import { buildRoutes } from "../../../utils/buildRoutes.utils.js";
import { Signup } from "./Signup/index.js";

const signupRoutes: RoutesTypes = [
    {
        path: "/",
        element: <Signup />,
    },
];

export const SignupRoutes = (): JSX.Element => {
    return <Routes>{buildRoutes(signupRoutes)}</Routes>;
};
