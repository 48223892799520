import { Icon } from "@waapi/waap-ui";
import styled from "styled-components";

export const ShowPasswordIcon = styled(Icon)`
    cursor: pointer;
`;

export const FieldContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[2]};
`;
