import { Languages } from "@/customHooks/useTranslation/types.js";
import { Select } from "@waapi/waap-ui";
import { forwardRef } from "react";
import { components } from "react-select";
import { useTheme } from "styled-components";
import { useTranslation } from "../../../customHooks/useTranslation/index.js";
import { extendedLabel, flagMatch } from "./constants.js";
import { StyledLangOptionLabel, StyledLanguageSelect, ValueContainerContent, getSelectStyles } from "./style.js";

const LangOptionLabel = ({ value, size }: { value: string; size: "small" | "large" }): JSX.Element => {
    return (
        <StyledLangOptionLabel size={size}>
            {flagMatch[value]}
            {size === "large" && <span>{extendedLabel[value]}</span>}
        </StyledLangOptionLabel>
    );
};

export const LanguageSelect = forwardRef<
    any,
    {
        size?: "small" | "large";
        variant?: "dark" | "light";
    }
>(({ size = "large", variant = "dark" }, ref) => {
    const { changeLanguage, currentLanguage, languages } = useTranslation();
    const theme = useTheme();

    const handleChange = (val?: Languages) => {
        if (val) changeLanguage(val);
    };

    return (
        <StyledLanguageSelect size={size}>
            <Select
                ref={ref}
                id="list-select"
                isSearchable={false}
                onChange={handleChange}
                value={currentLanguage}
                options={languages.map((ln) => ({ value: ln, label: <LangOptionLabel value={ln} size={size} /> }))}
                menuPlacement="top"
                styles={getSelectStyles({ variant, theme })}
                components={{
                    DropdownIndicator: ({ children, ...props }: any) =>
                        size === "large" ? <components.DropdownIndicator {...props}>{children}</components.DropdownIndicator> : null,
                    ValueContainer: ({ children, ...props }: any) => (
                        <components.ValueContainer {...props}>
                            <ValueContainerContent size={size}>{children}</ValueContainerContent>
                        </components.ValueContainer>
                    ),
                }}
            />
        </StyledLanguageSelect>
    );
});
