import React from "react";
import { Routes } from "react-router-dom";
import { RoutesTypes } from "../../../types/routes.types.js";
import { buildRoutes } from "../../../utils/buildRoutes.utils.js";
import { Impersonate } from "./Impersonate/index.js";

const impersonateRoutes: RoutesTypes = [
    {
        path: "/",
        element: <Impersonate />,
    },
];

export const ImpersonateRoutes = (): JSX.Element => {
    return <Routes>{buildRoutes(impersonateRoutes)}</Routes>;
};
