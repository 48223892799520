import { ITheme, Text, TextSize, TextWeight } from "@waapi/waap-ui";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "styled-components";
import { Container } from "./style.js";

export const SuccessPasswordForgotten = () => {
    const theme = useTheme() as ITheme;
    const { t, translations } = useTranslation();

    return (
        <Container>
            <Text size={TextSize.SMALL}>{t(translations.pages.forgotPassword.form.success.text())}</Text>
            <Link to="/login">
                <Text size={TextSize.SMALL} color={theme.colors.primary[100]} weight={TextWeight.SEMIBOLD}>
                    {t(translations.pages.forgotPassword.form.success.go_to_login())}
                </Text>
            </Link>
        </Container>
    );
};
