import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { useAccountManager } from "@/entities/Account/hooks/useAccountManager/index.js";
import { actions } from "@/entities/User/hooks/useUserState/slice.js";
import { ContactAdvantagesEnum } from "@waapi/palpatine-client";
import { Button, Select, Text, TextSize, TextWeight } from "@waapi/waap-ui";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { OnboardingPage } from "../OnboardingPage/index.js";
import { getAdvantagesTranslations } from "./helpers/getAdvantagesTranslations.js";
import { AdvantageSelector, AdvantageSelectorWrapper } from "./style.js";

export const ChooseAdvantage = (): JSX.Element => {
    const [loading, setLoading] = useState(false);
    const [advantage, setAdvantage] = useState(ContactAdvantagesEnum.ADVANTAGE_30);

    const { chooseAdvantage } = useAccountManager();
    const { t, translations } = useTranslation();
    const dispatch = useDispatch();
    const advantagesTranslations = getAdvantagesTranslations({ t, translations });

    const handleChooseAdvantage = async () => {
        setLoading(true);
        await chooseAdvantage({ params: [{ body: { contract: advantage } }], onAdvantageChosen: () => dispatch(actions.setAdvantage(advantage)) });
        setLoading(false);
    };

    const bodyComponent = (
        <>
            <AdvantageSelectorWrapper>
                <Text size={TextSize.SMALL} weight={TextWeight.SEMIBOLD}>
                    {t(translations.pages.choose_advantage.explanation())}
                </Text>

                <AdvantageSelector>
                    <Select
                        isSearchable={false}
                        value={advantage}
                        options={Object.values(ContactAdvantagesEnum).map((value) => ({
                            value,
                            label: advantagesTranslations[value],
                        }))}
                        onChange={(val) => val && setAdvantage(val)}
                    />
                </AdvantageSelector>
            </AdvantageSelectorWrapper>

            <div>
                <Button variant="tinted" loading={loading} onClick={handleChooseAdvantage}>
                    {t(translations.pages.choose_advantage.validate())}
                </Button>
            </div>
        </>
    );

    return (
        <OnboardingPage
            title={t(translations.pages.choose_advantage.title())}
            subtitles={[t(translations.pages.choose_advantage.subtitle_1()), t(translations.pages.choose_advantage.subtitle_2())]}
            body={bodyComponent}
        />
    );
};
