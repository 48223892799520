import { CountrySelect } from "./components/CountrySelect/index.js";
import { EmailInput } from "./components/EmailInput/index.js";
import { PasswordInput } from "./components/PasswordInput/index.js";
import { AuthFormComponent, Props, ReturnType } from "./types.js";

export const useAuthForm = <Components extends Array<AuthFormComponent>>({ components, state }: Props<Components>): ReturnType<Components> => {
    const buildPasswordComponent = (): JSX.Element => <PasswordInput formState={state.form} />;
    const buildEmailComponent = (): JSX.Element => <EmailInput formState={state.form} />;
    const buildCountryComponent = (): JSX.Element => <CountrySelect formState={state.form} />;

    const builderFunctionMap: Record<AuthFormComponent, () => JSX.Element> = {
        [AuthFormComponent.PASSWORD]: buildPasswordComponent,
        [AuthFormComponent.EMAIL]: buildEmailComponent,
        [AuthFormComponent.COUNTRY]: buildCountryComponent,
    };

    return {
        components: components.reduce<ReturnType<Components>["components"]>(
            (acc, component) => ({ ...acc, [component]: builderFunctionMap[component]() }),
            {} as ReturnType<Components>["components"],
        ),
    };
};
