import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { Button, Flex, ProgressBar, Text, TextSize, TextWeight } from "@waapi/waap-ui";
import { Trans } from "react-i18next";
import { useTheme } from "styled-components";
import { ImportProgressionWrapper, InlineText } from "./styles.js";
import { ImportProgressionProps } from "./types.js";

export const ImportProgression = (props: ImportProgressionProps) => {
    const { progression, maxProspectCount, onStopImport } = props;

    const { t, translations } = useTranslation();
    const theme = useTheme();

    return (
        <ImportProgressionWrapper direction="column" gap="12">
            <Flex direction="column" gap="4">
                <ProgressBar
                    value={Math.round(((progression?.success || 0) / maxProspectCount) * 100) || 0}
                    horizontalPlacement="left"
                    verticalPlacement="top"
                    renderLabel={({ value }) => (
                        <Text>
                            <Trans
                                i18nKey={translations.import.progress_bar_label()}
                                components={{
                                    strong: <InlineText color={theme.grayscale[100]} weight={TextWeight.SEMIBOLD} size={TextSize.LARGE} />,
                                }}
                                values={{
                                    value,
                                }}
                            />
                        </Text>
                    )}
                />
                <Text size={TextSize["X-SMALL"]}>
                    <Trans
                        i18nKey={translations.import.progress_summary()}
                        components={{
                            strong: <InlineText weight={TextWeight.BOLD} size={TextSize["X-SMALL"]} />,
                        }}
                        values={{
                            max: maxProspectCount,
                            scanned: progression?.scanned || 0,
                            success: progression?.success || 0,
                            error: progression?.error || 0,
                            duplicates: progression?.duplicates || 0,
                        }}
                    />
                </Text>
            </Flex>
            <Flex gap="12" justify="end">
                <Button color="error" onClick={onStopImport}>
                    {t(translations.import.stop_import())}
                </Button>
            </Flex>
        </ImportProgressionWrapper>
    );
};
