import { IconButton } from "@waapi/waap-ui";
import styled from "styled-components";

export const StyledLink = styled.div`
    display: block;
`;

export const StyledIconButton = styled(IconButton)`
    background: ${({ theme }) => theme.gradients.purpleToGreen};
    border-width: 0;

    svg {
        color: ${({ theme }) => theme.grayscale.white};
    }
`;
