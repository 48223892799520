import { motion } from "framer-motion";
import { OuterWrapper } from "./styles.js";

export const AnimatedOuterWrapper = motion(OuterWrapper);

export const variants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
    },
};
