import rocketTakingSpaceSvg from "@/assets/rocket-taking-space.svg";
import { Text } from "@waapi/waap-ui";
import styled from "styled-components";

export const StyledContainer = styled.div`
    background: url(${rocketTakingSpaceSvg}) no-repeat top right;
    background-size: auto 100%;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const StyledTitle = styled.h1`
    color: ${({ theme }) => theme.colors.primary[100]};
    font-size: 9.375rem;
`;

export const StyledText = styled(Text)`
    margin: 0 0 ${({ theme }) => theme.spacing[32]} 0;
    color: ${({ theme }) => theme.grayscale[100]};
    width: 40%;
`;
