import useRequest from "@/customHooks/useRequest.js";
import useToasts from "@/customHooks/useToasts.js";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { AMBASSADOR_BANNED_HTTP_RESPONSE_CODE, LOCAL_STORAGE_AMBASSADOR_ID_KEY } from "@/utils/constants.js";
import { IAmbassador } from "@waapi/palpatine-client";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIntercom } from "../../../../customHooks/useIntercom.js";
import { TOKEN_COOKIE_KEY } from "../useUserState/constants.js";
import { actions } from "../useUserState/slice.js";
import { SecondaryAccountType } from "../useUserState/types.js";

export const useUserManager = () => {
    const { addToast } = useToasts();
    const { logoutIntercom } = useIntercom();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t, translations } = useTranslation();

    const signup = useRequest<"signup">("signup");
    const login = useRequest<"login">("login");
    const updatePassword = useRequest<"updatePassword">("updatePassword");
    const forgetPassword = useRequest<"forgetPassword">("forgetPassword");

    const afterLoginOrSignup = ({ token, user }: { token: string; user: IAmbassador }) => {
        Cookies.set(TOKEN_COOKIE_KEY, token, { expires: dayjs().add(1, "hour").toDate() });
        localStorage.setItem(LOCAL_STORAGE_AMBASSADOR_ID_KEY, user._id);
        dispatch(actions.updateUserState({ auth: { rootAccount: { apiToken: token, user, hasRequirements: false } } }));
        navigate("/home");
    };

    const afterImpersonate = ({ token, user }: { token: string; user: IAmbassador }) => {
        Cookies.set(TOKEN_COOKIE_KEY, token, { expires: dayjs().add(10, "minute").toDate() });
        dispatch(
            actions.updateUserState({
                auth: { secondaryAccount: { apiToken: token, user, hasRequirements: false, type: SecondaryAccountType.IMPERSONATION } },
            }),
        );
        navigate("/home");
    };

    const handleLogin = async (...params: Parameters<typeof login>) => {
        const res = await login(...params);
        const isAmbassadorBanned = res.status === AMBASSADOR_BANNED_HTTP_RESPONSE_CODE;
        if (isAmbassadorBanned) {
            addToast(t(translations.forms.login.toasts.error_banned()), { appearance: "error", autoDismiss: true });
            return;
        }
        if (res.hasFailed) {
            addToast(t(translations.forms.login.toasts.error_login()), { appearance: "error", autoDismiss: true });
            return;
        }

        const { ambassador, authToken } = res.data;
        afterLoginOrSignup({ token: authToken, user: ambassador });
    };

    const handleLogout = async () => {
        Cookies.remove(TOKEN_COOKIE_KEY);
        logoutIntercom();
        dispatch(actions.logOutFromPrimaryAccount());
        navigate({ pathname: "/login" });
    };

    const handleSignup = async ({ params, onEmailTaken }: { params: Parameters<typeof signup>; onEmailTaken: () => void }) => {
        const res = await signup(...params);
        const isAmbassadorBanned = res.status === AMBASSADOR_BANNED_HTTP_RESPONSE_CODE;
        if (isAmbassadorBanned) {
            addToast(t(translations.forms.signup.toasts.error_banned()), { appearance: "error", autoDismiss: true });
            return;
        }
        if (res.hasFailed) {
            if (res.error.code === "R001509-001") {
                onEmailTaken();
                return;
            }

            addToast(t(translations.forms.signup.toasts.error_signup()), { appearance: "error", autoDismiss: true });
            return;
        }

        const { ambassador, authToken } = res.data;
        afterLoginOrSignup({ token: authToken, user: ambassador });
    };

    const handleUpdatePassword = async ({ params, onWrongPassword }: { params: Parameters<typeof updatePassword>; onWrongPassword: () => void }) => {
        const res = await updatePassword(...params);
        if (res.hasFailed) {
            if (res.error.code === "R021400-003") {
                onWrongPassword();
                return;
            }

            addToast(t(translations.pages.my_account.change_password.toasts.error_update_password()), { appearance: "error", autoDismiss: true });
            return;
        }

        addToast(t(translations.pages.my_account.change_password.toasts.success_update_password()), { appearance: "success", autoDismiss: true });
    };

    const handleForgetPassword = async ({ params, onEmailSent }: { params: Parameters<typeof forgetPassword>; onEmailSent: () => void }) => {
        const res = await forgetPassword(...params);

        // @INFO: don't show error toast if there is no account with this email
        if (res.hasFailed && res.error.code !== "R003404-001")
            addToast(t(translations.pages.forgotPassword.toasts.error_forgot_password()), { appearance: "error", autoDismiss: true });
        else onEmailSent();
    };

    return {
        login: handleLogin,
        signup: handleSignup,
        logout: handleLogout,
        updatePassword: handleUpdatePassword,
        forgetPassword: handleForgetPassword,
        impersonate: afterImpersonate,
    };
};
