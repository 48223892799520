import { Routes } from "react-router-dom";
import { RoutesTypes } from "../../../types/routes.types.js";
import { buildRoutes } from "../../../utils/buildRoutes.utils.js";
import { Affiliates } from "./Affiliates/Loadable.js";

const affiliatesRoutes: RoutesTypes = [
    {
        path: "/",
        element: <Affiliates />,
    },
];

export const AffiliatesRoutes = (): JSX.Element => {
    return <Routes>{buildRoutes(affiliatesRoutes)}</Routes>;
};
