import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[32]};
`;

export const MenuItems = styled.div`
    display: flex;
    flex-direction: column;
`;
