import React from "react";
import WaalienText from "../../../../assets/waalaxy_text_white.svg";
import Waalien from "../../../../assets/waalien_official.svg";
import { Container, LogoImage, TextImage } from "./style.js";

export const WaalienWhiteLogo = (): JSX.Element => {
    return (
        <Container>
            <LogoImage src={Waalien} />
            <TextImage src={WaalienText} />
        </Container>
    );
};
