import { Card as InnerCard, Container as InnerContainer } from "@waapi/waap-ui";
import styled from "styled-components";
import FirefliesBackground from "../../../assets/fireflies_background.svg";

export const Wrapper = styled.div`
    background: url(${FirefliesBackground}) no-repeat center fixed, ${(props) => props.theme.gradients.blueToPurple};
    width: 100%;
    height: 100%;
`;

export const Container = styled(InnerContainer)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${(props) => props.theme.spacing[16]};
    overflow: hidden;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const LanguageSelectWrapper = styled.div`
    width: 9rem;
`;

export const BodyWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Body = styled.div<{ width: string }>`
    position: relative;
    width: ${(props) => props.width};
`;

export const Card = styled(InnerCard)`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing[32]};
    padding: ${(props) => props.theme.spacing[64]};
    overflow-y: auto;
    max-height: 45rem;
`;

export const Circles = styled.img<Partial<Pick<CSSStyleDeclaration, "top" | "bottom" | "right" | "left">>>`
    position: absolute;
    ${(props) => props.top && `top: ${props.top}`};
    ${(props) => props.bottom && `bottom: ${props.bottom}`};
    ${(props) => props.right && `right: ${props.right}`};
    ${(props) => props.left && `left: ${props.left}`};
`;

export const Strong = styled.span`
    color: ${(props) => props.theme.colors.primary[100]};
`;

export const Subtitle = styled.div`
    display: flex;
    align-items: center;
    gap: ${(props) => props.theme.spacing[4]};
`;

export const Form = styled.div``;
