import { AnimatePresence } from "framer-motion";
import React from "react";
import { AnimatedOuterWrapper, variants } from "./animations.js";

export const Fade = ({ children, outerWrapperStyles }: { outerWrapperStyles?: React.CSSProperties; children: JSX.Element }): JSX.Element => (
    <AnimatePresence>
        <AnimatedOuterWrapper style={outerWrapperStyles} initial={variants.hidden} animate={variants.visible} exit={variants.hidden}>
            {children}
        </AnimatedOuterWrapper>
    </AnimatePresence>
);
