import { EmailInputErrors } from "@/app/components/AuthForm/components/EmailInput/validation.js";
import { useAuthForm } from "@/app/components/AuthForm/index.js";
import { AuthFormComponent } from "@/app/components/AuthForm/types.js";
import { useTranslation } from "@/customHooks/useTranslation/index.js";
import { useUserManager } from "@/entities/User/hooks/useUserManager/index.js";
import { getAmbassadorIdFromLocalStorage } from "@/helpers/banHelpers.js";
import { Button } from "@waapi/waap-ui";
import { Formik, FormikHelpers } from "formik";
import { useMemo } from "react";
import { Footer, Form, InputsWrapper } from "./style.js";
import { SignupValues } from "./types.js";
import { signupValidationSchema } from "./validation.js";

export const SignupForm = (): JSX.Element => {
    const { t, translations, currentLanguage } = useTranslation();
    const { signup } = useUserManager();

    const initialValues = useMemo<SignupValues>(() => ({ email: "", password: "", country: "", agree: true }), []);

    const onSignup = async (values: SignupValues, helpers: FormikHelpers<SignupValues>) => {
        const previousAmbassadorId = getAmbassadorIdFromLocalStorage();
        return signup({
            params: [
                {
                    body: {
                        ...values,
                        language: currentLanguage,
                        previousAmbassadorId,
                    },
                },
            ],
            onEmailTaken: () => helpers.setFieldError("email", EmailInputErrors.EMAIL_ALREADY_TAKEN),
        });
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={signupValidationSchema}
            onSubmit={onSignup}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {(formState) => {
                const { components } = useAuthForm({
                    state: { form: formState },
                    components: [AuthFormComponent.EMAIL, AuthFormComponent.PASSWORD, AuthFormComponent.COUNTRY],
                });

                return (
                    <Form>
                        <InputsWrapper>
                            {components.email}
                            {components.password}
                            {components.country}
                        </InputsWrapper>

                        <Footer>
                            <Button type="submit" loading={formState.isSubmitting}>
                                {t(translations.pages.signup.form.submit.text())}
                            </Button>
                        </Footer>
                    </Form>
                );
            }}
        </Formik>
    );
};
