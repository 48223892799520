import { Text } from "@waapi/waap-ui";
import styled from "styled-components";

export const StyledSpinnerContent = styled.div`
    width: 300px;
    height: 300px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledSpinnerPlanet = styled.div<{ animationState?: "playing" | "stopped" }>`
    width: 65%;
    height: 65%;
    background-color: #6e7191;
    border-radius: ${({ theme }) => theme.radius.round};
    position: absolute;
    display: flex;
    align-items: center;
    transform-origin: center center;
    box-shadow: inset 2px -10px 0px rgba(0, 0, 0, 0.1);
    animation: planet 5s ease infinite alternate;
    animation-play-state: ${({ animationState }) => (animationState === "playing" ? "running" : "paused")};

    @keyframes planet {
        0% {
            transform: rotate(10deg);
        }

        100% {
            transform: rotate(-10deg);
        }
    }
`;

export const StyledSpinnerRing = styled.div<{ animationState?: "playing" | "stopped" }>`
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: ${({ theme }) => theme.radius.round};
    background-color: #eaeefd;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: 33% center;
    box-shadow: 2px -10px 0px rgba(0, 0, 0, 0.1), inset -5px -10px 0px rgba(0, 0, 0, 0.1);
    animation: ring 3s ease infinite;
    animation-play-state: ${({ animationState }) => (animationState === "playing" ? "running" : "paused")};

    @keyframes ring {
        0% {
            transform: rotateX(110deg) rotateZ(0deg) translate(-50px, 5px);
        }

        100% {
            transform: rotateX(110deg) rotateZ(360deg) translate(-50px, 5px);
        }
    }

    /* small ball */
    &:before {
        content: "";
        position: absolute;
        width: 10px;
        height: 30px;
        border-radius: ${({ theme }) => theme.radius.round};
        background-color: #8598ee;
        z-index: ${({ theme: { elevationLevels } }) => elevationLevels.two};
        left: calc(0px - 5px);
        box-shadow: inset -3px 3px 0px rgba(0, 0, 0, 0.2);
    }

    /* inner ring */
    &:after {
        content: "";
        position: absolute;
        width: 240px;
        height: 240px;
        border-radius: ${({ theme }) => theme.radius.round};
        background-color: #8598ee;
        box-shadow: inset 2px -10px 0px rgba(0, 0, 0, 0.1);
    }
`;

export const StyledSpinnerCoverRing = styled.div`
    position: absolute;
    margin: 1px;
    width: 100%;
    height: 50%;
    border-bottom-left-radius: 80%;
    border-bottom-right-radius: 80%;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    transform: translate(0px, -17px);
    background-color: #4e4b66;
    z-index: ${({ theme: { elevationLevels } }) => elevationLevels.two};
    box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.01);
`;

export const StyledSpinnerSpots = styled.div<{ animationState?: "playing" | "stopped" }>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: ${({ theme: { elevationLevels } }) => elevationLevels.two};

    & > span {
        width: 30px;
        height: 30px;
        background-color: #d6d8e7;
        position: absolute;
        border-radius: ${({ theme }) => theme.radius.round};
        box-shadow: inset -2px 3px 0px rgba(0, 0, 0, 0.1);
        animation: dots 5s ease infinite alternate;
        animation-play-state: ${({ animationState }) => (animationState === "playing" ? "running" : "paused")};

        @keyframes dots {
            0% {
                box-shadow: inset -3px 3px 0px rgba(0, 0, 0, 0.1);
            }
            100% {
                box-shadow: inset 3px 3px 0px rgba(0, 0, 0, 0.1);
            }
        }

        &:nth-child(1) {
            top: 20px;
            right: 50px;
        }

        &:nth-child(2) {
            top: 40px;
            left: 50px;
            width: 15px;
            height: 15px;
        }

        &:nth-child(3) {
            top: 80px;
            left: 20px;
            width: 25px;
            height: 25px;
        }

        &:nth-child(4) {
            top: 80px;
            left: 90px;
            width: 40px;
            height: 40px;
        }

        &:nth-child(5) {
            top: 160px;
            left: 70px;
            width: 15px;
            height: 15px;
        }

        &:nth-child(6) {
            top: 165px;
            left: 125px;
            width: 10px;
            height: 10px;
        }

        &:nth-child(7) {
            top: 90px;
            left: 150px;
            width: 15px;
            height: 15px;
        }
    }
`;

export const StyledSpinnerText = styled(Text)`
    z-index: ${({ theme: { elevationLevels } }) => elevationLevels.two};
    position: absolute;
    bottom: -20px;
    width: fit-content;
    text-align: center;
`;
