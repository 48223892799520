import sendMessage from "@/utils/chromeMessages.js";
import { ProspectList } from "@/customHooks/useExtensionManager/types.js";

export function createProspectList(name: string): Promise<ProspectList | null> {
    return sendMessage<ProspectList>({
        action: "PROSPECT_LIST/CREATE",
        params: {
            prospectListName: name,
        },
    })
        .then((response) => {
            if (response && "error" in response) {
                return null;
            }

            return response || null;
        })
        .catch(() => {
            return null;
        });
}
