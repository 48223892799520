import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContactAdvantagesEnum } from "@waapi/palpatine-client";
import dayjs from "dayjs";
import { ContainerState } from "./types.js";

// The initial state of the UserState container
export const initialState: ContainerState = {
    isLoginToAnOtherAccount: false,
    auth: {},
    reloadExtension: false,
    isLoadingUser: false,
};

const userStateSlice = createSlice({
    name: "useUserState",
    initialState,
    reducers: {
        updateUserState(state, action: PayloadAction<Partial<ContainerState>>) {
            state.isLoginToAnOtherAccount = action.payload.isLoginToAnOtherAccount ?? state.isLoginToAnOtherAccount;
            state.auth = { ...state.auth, ...action.payload.auth };
            state.isLoadingUser = action.payload.isLoadingUser ?? state.isLoadingUser;
        },
        setWaalaxyAccount(
            state,
            action: PayloadAction<{
                waalaxyId: string;
                firstName?: string;
                lastName?: string;
                profilePicture?: string;
            }>,
        ) {
            const { waalaxyId, firstName, lastName, profilePicture } = action.payload;

            if (state.auth.secondaryAccount?.user)
                state.auth.secondaryAccount.user = {
                    ...state.auth.secondaryAccount.user,
                    waalaxyId,
                    linkedinAccount: {
                        firstName,
                        lastName,
                        profilePicture,
                    },
                };
            else if (state.auth.rootAccount?.user)
                state.auth.rootAccount.user = {
                    ...state.auth.rootAccount.user,
                    waalaxyId,
                    linkedinAccount: {
                        firstName,
                        lastName,
                        profilePicture,
                    },
                };
        },
        setHasRequirements(state, action: PayloadAction<boolean>) {
            if (state.auth.secondaryAccount) state.auth.secondaryAccount.hasRequirements = action.payload;
            else if (state.auth.rootAccount) state.auth.rootAccount.hasRequirements = action.payload;
        },
        logOutFromPrimaryAccount(state) {
            state.auth = initialState.auth;
        },
        setAdvantage(state, action: PayloadAction<ContactAdvantagesEnum>) {
            if (state.auth.secondaryAccount?.user.affiliation) state.auth.secondaryAccount.user.affiliation.contract = action.payload;
            else if (state.auth.rootAccount?.user?.affiliation) state.auth.rootAccount.user.affiliation.contract = action.payload;
        },
        logOutFromSecondaryAccount(state) {
            state.auth.secondaryAccount = initialState.auth.secondaryAccount;
        },
        reloadExtension(state, action: PayloadAction<boolean>) {
            state.reloadExtension = action.payload;
        },
        setAcceptGoldenRules(state) {
            if (state?.auth?.rootAccount?.user) {
                state.auth.rootAccount.user.metadata = {
                    ...state.auth.rootAccount.user.metadata,
                    countAffiliates: state.auth.rootAccount.user?.metadata?.countAffiliates || 0,
                    amountPayoutPaid: state.auth.rootAccount.user?.metadata?.amountPayoutPaid || 0,
                    amountPayoutInTransit: state.auth.rootAccount.user?.metadata?.amountPayoutInTransit || 0,
                    payingAffiliates: state.auth.rootAccount.user?.metadata?.payingAffiliates || 0,
                    lastLoginAt: dayjs().toISOString(),
                    hasAgreedGoldenRulesAt: dayjs().toISOString(),
                };
            }
        },
        setAffiliatesSummaryFromLastLogin(
            state,
            action: PayloadAction<{
                countAffiliatesFromLastLogin: number;
                countPayingAffiliatesFromLastLogin: number;
                newAffiliatePaymentsFromLastLogin: Array<string>;
                newAffiliatesCanceledFromLastLogin: Array<string>;
            }>,
        ) {
            if (state?.auth?.rootAccount?.user) {
                state.auth.rootAccount.user = {
                    ...state.auth.rootAccount.user,
                    countAffiliatesFromLastLogin: action.payload.countAffiliatesFromLastLogin,
                    countPayingAffiliatesFromLastLogin: action.payload.countPayingAffiliatesFromLastLogin,
                    newAffiliatePaymentsFromLastLogin: action.payload.newAffiliatePaymentsFromLastLogin,
                    newAffiliatesCanceledFromLastLogin: action.payload.newAffiliatesCanceledFromLastLogin,
                };
            }
        },
        setLanguage(state, action: PayloadAction<string>) {
            if (state?.auth?.rootAccount?.user) {
                state.auth.rootAccount.user.language = action.payload;
            }
        },
    },
});

export const { actions, reducer, name: sliceKey } = userStateSlice;
