import styled from "styled-components";
import { Modal, Image, Card, flex, Text } from "@waapi/waap-ui";

export const StyledModal = styled(Modal)`
    background: ${({ theme }) => theme.grayscale.white};
    width: 30rem;
`;

export const StyledImageLeft = styled(Image)`
    position: relative;
    left: 26px;
    z-index: ${({ theme }) => theme.elevationLevels.one};
`;

export const StyledImageRight = styled(Image)`
    position: relative;
    right: 5px;
`;

export const StyledImageCenter = styled(Image)`
    position: relative;
    z-index: ${({ theme }) => theme.elevationLevels.two};
`;

export const StyledProfileCard = styled(Card)`
    ${flex};
    background: ${({ theme }) => theme.grayscale.white};
    border: 1px solid ${({ theme }) => theme.grayscale[50]};
`;

export const StyledTextCenter = styled(Text)`
    text-align: center;
`;
