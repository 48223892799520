import { Heading, HeadingSize, HeadingWeight, ITheme, useBreakpoint } from "@waapi/waap-ui";
import { LanguageSelect } from "@/app/containers/LanguageSelect/index.js";
import React from "react";
import { Trans } from "react-i18next";
import { useTheme } from "styled-components";
import CirclesHorizontal from "../../../assets/circles_horizontal.svg";
import CirclesVertical from "../../../assets/circles_vertical.svg";
import { BODY_WIDTH_MAP } from "./constants.js";
import { FooterAuthPage } from "./FooterAuthPage/index.js";
import { Body, BodyWrapper, Card, Circles, Container, Form, Header, LanguageSelectWrapper, Strong, Wrapper } from "./style.js";
import { AuthPageProps } from "./types.js";
import { WaalienWhiteLogo } from "./WaalienWhiteLogo/index.js";

export const AuthPage = ({ title, subtitle, form }: AuthPageProps): JSX.Element => {
    const breakpoint = useBreakpoint();
    const theme = useTheme() as ITheme;

    return (
        <Wrapper>
            <Container>
                <Header>
                    <WaalienWhiteLogo />
                    <LanguageSelectWrapper>
                        <LanguageSelect size="large" variant="light" />
                    </LanguageSelectWrapper>
                </Header>

                <BodyWrapper>
                    <Body width={BODY_WIDTH_MAP[breakpoint]}>
                        <Circles top="30%" left="102%" src={CirclesHorizontal} />
                        <Card>
                            <Heading color={theme.grayscale.white} weight={HeadingWeight.BOLD} size={HeadingSize.MEDIUM}>
                                <Trans i18nKey={title} components={{ strong: <Strong /> }} />
                            </Heading>

                            <Form>{form}</Form>

                            <FooterAuthPage subtitle={subtitle} />
                        </Card>
                        <Circles top="85%" right="102%" src={CirclesVertical} />
                    </Body>
                </BodyWrapper>
            </Container>
        </Wrapper>
    );
};
