import { CustomTFunction, TranslationDictionary } from "@/customHooks/useTranslation/types.js";
import * as Yup from "yup";

export enum PasswordInputErrors {
    PASSWORD_REQUIRED = "passwordRequired",
    PASSWORD_MIN_LENGTH = "passwordMinLength",
}

export const passwordInputErrorMap = ({
    t,
    translations,
}: {
    t: CustomTFunction;
    translations: TranslationDictionary;
}): Record<PasswordInputErrors, string> => ({
    [PasswordInputErrors.PASSWORD_REQUIRED]: t(translations.forms.auth.password.feedback.error.required()),
    [PasswordInputErrors.PASSWORD_MIN_LENGTH]: t(translations.forms.auth.password.feedback.error.min_length()),
});

export const passwordInputValidationSchema = Yup.object({
    password: Yup.string().required(PasswordInputErrors.PASSWORD_REQUIRED).min(8, PasswordInputErrors.PASSWORD_MIN_LENGTH),
});
